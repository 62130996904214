.containerDate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .containerYearBlog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerYearAndMonth {
      margin: 10px;

      .containerBtnYear {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btnYear {
          border: none;
          font-size: 18px;
          background-color: inherit;
          font-family: "Manrope", sans-serif;
          color: var(--principale);
          font-weight: 200;
        }

        .btnYear:hover {
          cursor: pointer;
        }

        .containerMonthBlog {
          display: block;

          .btnMonth {
            border: none;
            font-size: 18px;
            margin: 10px;
            background-color: inherit;
            font-family: "Manrope", sans-serif;
            color: var(--principale);
            font-weight: 200;
          }

          .btnMonth:hover {
            cursor: pointer;
          }
        }

        .none {
          display: none;
        }
      }
    }
  }
}
