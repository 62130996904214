.logPartnerSection {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .logPartnerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--principale);
    width: 40%;
    height: 50%;

    .logPartnerLogo {
      height: 150px;
    }

    .logPartnerPword {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logPartnerForgotPword {
        font-size: small;
        text-decoration: none;
        color: var(--autre);
      }
    }
  }
}
