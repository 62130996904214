.PopupConfirm-title {
  text-align: center;
  font-size: 20px;
  margin: unset;
}

.PopupConfirm-containerBtns {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
