.containerSectionThumbnails {
  display: flex;
  justify-content: center;

  .containerThumbnailsGlobal {
    width: 70%;

    .containerFirstLine {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      height: 500px;

      .thumbnail {
        text-decoration: none;
        color: inherit;
        padding: 2%;
      }

      .containerImgThumbnail {
        width: 100%;
        height: 80%;
        overflow: hidden;
        border-radius: 10px;

        .imgFirstThumbnail {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .titleThumbnail {
        color: var(--principale);
      }

      .containerDates {
        display: flex;

        .listDates {
          padding: 0 5px;
        }
      }
    }

    .containerFirstLine:nth-child(1) {
      .thumbnail:nth-child(1) {
        width: 60%;
      }

      .thumbnail:nth-child(2) {
        width: 35%;
      }
    }

    .containerFirstLine:nth-child(2) {
      .thumbnail:nth-child(1) {
        width: 35%;
      }

      .thumbnail:nth-child(2) {
        width: 60%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerSectionThumbnails {
    .containerThumbnailsGlobal {
      width: 100%;

      .containerFirstLine {
        height: 350px;
      }

      .containerFirstLine:nth-child(1) {
        .thumbnail:nth-child(1) {
          width: 60%;
        }

        .thumbnail:nth-child(2) {
          width: 35%;
        }
      }

      .containerFirstLine:nth-child(2) {
        .thumbnail:nth-child(1) {
          width: 35%;
        }

        .thumbnail:nth-child(2) {
          width: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .containerSectionThumbnails {
    .containerThumbnailsGlobal {
      width: 100%;

      .containerFirstLine {
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        height: auto;

        .containerImgThumbnail {
          height: 300px;
        }
      }

      .containerFirstLine:nth-child(1) {
        .thumbnail:nth-child(1) {
          width: 90%;
        }

        .thumbnail:nth-child(2) {
          width: 90%;
        }
      }

      .containerFirstLine:nth-child(2) {
        .thumbnail:nth-child(1) {
          width: 90%;
        }

        .thumbnail:nth-child(2) {
          width: 90%;
        }
      }
    }
  }
}
