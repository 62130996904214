.adminActivitesPage {
  position: relative;
}

.activiteDuParcChoisi {
  width: 90%;
}

.zoneChoixParcActivite {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.textSelect {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin-right: 30px;
}

.selectionParc {
  margin-left: 40px;
  width: 600px;
  height: 40px;
  font-size: 18px;
  font-family: inherit;
  background-color: inherit;
  border: solid 1px var(--principale);
  border-radius: 5px;
  text-align: center;
  color: inherit;
}

.commandeAdminFiltre {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .commandeAdminFormFiltre {
    display: flex;
  }
}

// delete
.zoneModify {
  margin-top: 30px;
  position: relative;
  padding: 30px 0;
  margin-bottom: 30px;
  width: 90%;
}

.sectionAdminActivites {
  padding-top: 20px;
  padding-left: 400px;
  padding-bottom: 50px;
  height: 100%;
}

.activiteAdminList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: solid 2px var(--principale);
  padding: 15px;
  margin: 5px 0;
  position: relative;
}

.nomActivite {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  width: 375px;
  white-space: nowrap;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zoneTextActivite {
  width: 700px;
  height: 200px;
  padding: 20px 15px 0 20px;
  margin-right: 20px;
}

.activiteAdminContainerBtn {
  position: absolute;
  right: 0px;
}

.btnModifActivite {
  width: 250px;
  height: 40px;
  background-color: var(--principale);
  color: white;
  border: none;
  font-size: 18px;
  margin-right: 10px;
}
.btnModifActivite:hover {
  width: 250px;
  height: 40px;
  background-color: var(--bouton);
  color: var(--principale);
  font-size: 18px;
  cursor: pointer;
}

.activiteAdminContainerAdd {
  position: relative;
  padding: 25px 0;
  margin-bottom: 50px;
  width: 100%;
}

.activiteAdminContainerMapModify {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.activiteAdminContainerLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.twoColumnsActivityPrice {
  display: flex;
  align-items: center;
}

.activiteAdminLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    margin-bottom: 30px;
    text-align: center;
  }
}

.activiteAdminLabelDes {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

.activiteAdminNewName {
  width: 30%;
  height: 35px;
  text-indent: 5px;
  margin: 10px 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.activiteAdminTarif {
  width: 8%;
  height: 35px;
  text-indent: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.activiteAdminNewDescription {
  width: 100%;
  margin: 10px 0;
  text-indent: 5px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  height: 200px;
}

.btnAjoutActivite {
  width: 250px;
  height: 40px;
  background-color: var(--principale);
  color: white;
  border: none;
  font-size: 18px;
  margin-right: 10px;
}
.btnAjoutActivite:hover {
  width: 250px;
  height: 40px;
  background-color: var(--bouton);
  color: var(--principale);
  font-size: 18px;
  cursor: pointer;
}

.containerTarifsetBouton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.activiteAdminContainerBtn {
  position: absolute;
  right: 0px;
}

.activiteAdminBtn {
  border: none;
  background-color: var(--principale);
  color: var(--police);
  width: 100px;
  height: 35px;
  padding: 0 10px;
  margin: 0 5px;
}

.activiteAdminBtn:hover {
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
}

.activiteAdminTitleUploadImage {
  padding: 15px;
}

.activiteAdminLabelUploadImg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 90%;
  margin: 10px 0;
  align-items: center;
  padding-bottom: 20px;
}

.activiteAdminLabelUploadImg2 {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;
  padding-bottom: 20px;
}

.activiteAdminInputUploadDescription {
  height: 35px;
  margin: 0 20px;
  text-align: center;
  color: inherit;
  font-family: inherit;
}

.activiteAdminInputUploadDescriptionBtn {
  border: none;
  background-color: var(--principale);
  color: var(--police);
  width: 200px;
  height: 35px;
  padding: 0 10px;
  margin: 0 5px;
}

.activiteAdminInputUploadDescriptionBtn:hover {
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
}

.activiteAdminContainerUploadImg {
  display: flex;
  justify-content: center;
  width: 90%;
}

.activiteAdminContainerImgModify {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.activiteAdminImgModify {
  max-width: 250px;
  max-height: 250px;
  margin: 10px;
}

.activiteAdminContainerBtnAdd {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.containterInputActiviteAdminModify {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
