.containerModifyPartner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .listAccomodation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .oneAccomodation {
      & input {
        margin-right: 5px;
      }
    }
  }
}
