.adminPage {
  position: relative;
}

.adminWelcome {
  position: fixed;
  top: 45%;
  left: 50%;
}

.adminWelcome p {
  font-size: 50px;
  color: var(--principale);
}
