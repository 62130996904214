.containerConceptGroupText {
  margin: 50px 400px;

  p {
    text-align: center;
  }
}

.containerGroupGlobal {
  width: 100%;
  display: flex;

  .selection-container {
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  .btns-container {
    display: flex;
    justify-content: space-around;
  }

  p {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    color: var(--principale);
  }

  .selected,
  .unselected {
    height: 100px;
    width: 300px;
    margin: 0 auto;
    border: inherit;
  }

  .selected {
    background-color: var(--autre);
  }

  .unselected {
    background-color: inherit;
    border: inherit;
  }

  .unselected:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: var(--autre);
  }
}

.container-products {
  display: flex;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.containerDuParc-btn {
  all: unset;
  padding: 30px 0;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
  background-color: var(--autre);
  height: 240px;
  cursor: pointer;
  transition: 0.5s;
  .zoneTextParc {
    margin: 10px auto;
  }
}

.disabled {
  all: unset;
  padding: 30px 0;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
  background-color: var(--autre);
  height: 240px;
  transition: 0.5s;
  cursor: not-allowed;
  .zoneTextParc {
    margin: 10px auto;
  }
  .imgDuParc {
    filter: grayscale(100%);
  }
  .imgDuParc:hover {
    transform: scale(1);
  }
}

.imgDuParc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgDuParc:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.05);
}

.zoneTextTraitGroupe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.toggle-mobile {
  width: 40%;
}

.add-btn,
.remove-btn {
  width: auto;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 6px;
  border: none;
}

.add-btn {
  background-color: var(--principale);
  color: var(--police);
}

.add-btn:hover {
  background-color: var(--bouton);
  color: var(--principale);
}

.remove-btn {
  background-color: var(--police);
  color: var(--principale);
  border: 1px solid var(--principale);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerGroupGlobal {
    .selection-container {
      width: 100%;
    }

    .selected,
    .unselected {
      height: 60px;
      width: 150px;
      margin: 0 auto;
      border: inherit;

      p  {
        font-size: 12px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }

  .containerConceptGroupText {
    margin: 20px;
  }

  .containerDuParc-btn {
    width: 30%;
  }

  .toggle-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .containerGroupGlobal {
    .selection-container {
      width: 100%;
    }
    .max-capacity {
      font-size: 13px;
    }
  }

  .add-btn,
  .remove-btn {
    height: 25px;
    margin-top: 8px;
  }

  .containerDuParc-btn,
  .disabled {
    width: 80%;
  }

  .containerConceptGroupText {
    margin: 20px;
  }

  .toggle-mobile {
    display: none;
  }
}
