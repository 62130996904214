.sectionFooter {
  background: linear-gradient(var(--principale), #000);
  height: auto;
  width: 100%;
}

.containerFooter {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 50px 150px;
}

.div1 {
  text-align: center;
}

.dividerFooter {
  height: 125px;
  width: 1px;
  background-color: #fff;
}

.divMilieu {
  text-align: center;
}

.fb {
  height: 40px;
  margin: 15px;
}

.fb:hover {
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: scale(1.2);
}

.div3 {
  text-align: center;
}

.titreFooter {
  color: var(--bouton);
  font-size: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 200;
}

.paraphFooterContainerP {
  text-decoration: none;
}

.paraphFooter {
  color: var(--police);
  margin: 2.5px 0;
}

.paraphFooter2 {
  color: var(--police);
  margin: 2.5px 0;
}

.paraphFooter:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.2);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerFooter {
    padding: 25px 50px;
  }

  .fb {
    height: 30px;
  }

  .div3 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .containerFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
  }

  .dividerFooter {
    height: 1px;
    width: 50%;
    margin: 25px 0;
  }

  .fb {
    height: 30px;
  }

  // .titreFooter {
  //   font-size: 15px;
  // }

  // .paraphFooter {
  //   color: #fff;
  //   font-size: 15px;
  //   font-family: "Roboto", sans-serif;
  //   margin: 8px auto;
  // }
  // .paraphFooter2 {
  //   color: #fff;
  //   font-size: 15px;
  //   font-family: "Roboto", sans-serif;
  //   margin: 8px auto;
  // }
  // .divMilieu {
  //   padding-top: 30px;
  //   display: flex;
  //   flex-direction: column;
  //   align-content: flex-start;
  //   align-items: center;
  // }

  .div3 {
    display: none;
  }
}
