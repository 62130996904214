.listOptionAdmin {
  .oneCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    border: solid 2px var(--principale);
    margin: 5px 0;
    padding-left: 5px;
  }
}
