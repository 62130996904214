.accomodationAdminGlobal {
  padding-left: 400px;
  padding-top: 20px;

  .containerSelect {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .listAccomodation {
    margin-bottom: 50px;

    .oneCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 2px var(--principale);
      width: 90%;
      margin: 5px 0;

      .accomodationName {
        padding-left: 5px;
      }

      .cardBtn {
        display: flex;
        align-items: center;

        .groupIcon {
          margin-right: 30px;
          font-size: 25px;
        }
      }
    }
  }
}
