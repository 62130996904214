.toggle-groupe-container {
  display: flex;
}

.filtre-label {
  display: flex;
  align-items: center;
  margin: 0 1em;
  height: 40px;
}

.filtre-checkbox {
  margin: 0 1em;
}
