.sectionBlog {
  max-width: 70%;
  width: 100%;
  height: auto;
}

.containerMapBlogArticles {
  width: 100%;
  margin: 50px 0 0 0;
  border-right: solid 2px var(--principale);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerBlogArticle {
  width: 70%;
  margin: 0 25px 100px 25px;
}

.dateAndKeywords {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
  font-family: "manrope", sans-serif;
}

.dateBlogArticle {
  color: var(--principale);
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.containerBlogKeyword {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.blogKeywords {
  color: var(--principale);
  margin: 0 5px;
}

.containerBlogImg {
  display: flex;
  justify-content: center;
}

.imageBlogArticle {
  width: 100%;
}

.articleBlogPublic {
  max-height: 200px;
  overflow: hidden;

  p,
  h1,
  h2,
  h3,
  li {
    display: none;
  }

  p,
  h1,
  h2,
  h3,
  li:nth-child(1) {
    display: block;
  }

  p,
  h1,
  h2,
  h3,
  li:nth-child(2) {
    display: block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.articleBlogPublic li {
  margin-left: 50px;
}

.articleBlogPublic h1 {
  font-family: "Amatic SC", cursive;
  font-weight: 800px;
  text-align: center;
  font-size: 45px;
  margin: 20px 0;
}

.articleBlogPublic h2 {
  font-family: "Manrope", cursive;
  font-weight: 600px;
  text-align: center;
  margin: 20px 50px;
}

.articleBlogPublic h3 {
  font-family: "Manrope", cursive;
  font-weight: 300px;
  margin: 20px 25px;
}

.articleBlogComplet {
  height: auto;
}

.articleBlogComplet li {
  margin-left: 50px;
}

.articleBlogComplet h1 {
  font-family: "Amatic SC", cursive;
  font-weight: 800px;
  text-align: center;
  font-size: 45px;
  margin: 20px 0;
}

.articleBlogComplet h2 {
  font-family: "Manrope", cursive;
  font-weight: 600px;
  text-align: center;
  margin: 20px 50px;
}

.articleBlogComplet h3 {
  font-family: "Manrope", cursive;
  font-weight: 300px;
  margin: 20px 25px;
}

.containerBlogReadMore {
  display: flex;
  justify-content: center;
}

.btnBlogReadMore {
  margin: 25px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.containerBtnMoreArticlesTrue {
  display: block;
}

.containerBtnMoreArticlesFalse {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sectionBlog {
    max-width: 100%;
  }

  .containerMapBlogArticles {
    border: none;
  }

  .containerBlogArticle {
    margin: 25px;
  }
}

@media screen and (max-width: 767px) {
  .sectionBlog {
    max-width: 100%;
  }

  .containerMapBlogArticles {
    border: none;
  }

  .containerBlogArticle {
    margin: 25px;
  }
}
