.containerGlobalFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 90px;

  .btnFilter {
    display: none;
  }

  .btnFilterNone {
    display: none;
  }

  .containerFilter {
    width: 65%;
    display: flex;
    justify-content: center;

    .selectFilter {
      background: white;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      border: solid 1px var(--principale);
      width: 200px;
      text-align: center;
      text-align-last: center;
      height: 40px;
      box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
      cursor: pointer;
    }

    .selectFilter:nth-child(1) {
      border-radius: 10px 0 0 10px;
    }

    .selectFilter:nth-child(3) {
      border-radius: 0 10px 10px 0;
    }
  }

  .containerFilterFixed {
    position: fixed;
    top: 80px;
    z-index: 10;
    animation: enterTop 0.3s linear;

    .selectFilter {
      background: white;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      border: solid 1px var(--principale);
      width: 200px;
      text-align: center;
      text-align-last: center;
      height: 40px;
      box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
      cursor: pointer;
    }

    .selectFilter:nth-child(1) {
      border-radius: 0 0 0 10px;
    }

    .selectFilter:nth-child(3) {
      border-radius: 0 0 10px 0;
    }
  }
}

@keyframes enterTop {
  from {
    top: 40px;
  }
  to {
    top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .containerGlobalFilter {
    position: fixed;
    bottom: 10px;
    z-index: 2;
    width: 100vw;
    // height: 85vh;

    .btnFilter {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 0;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: white;
      border: solid 1px var(--principale);
      border-radius: 50%;
      box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
      animation: btnFilterOn 0.5s linear;

      .filterLogo {
        width: 50%;
        height: 50%;
      }
    }

    .containerFilter {
      position: fixed;
      animation: closeFilterMobile 0.3s linear;
      top: unset;
      bottom: 10px;
      right: 10px;
      height: 40px;
      width: 0;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      z-index: -1;

      .selectFilter {
        animation: inputOff 0.3s linear;
      }
    }

    .containerFilterFixed {
      top: unset;
      bottom: 10px;
      right: 10px;
      height: 200px;
      width: 95%;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      animation: openFilterMobile 0.3s linear;
      z-index: -1;

      .selectFilter {
        animation: inputOn 0.3s linear;
      }

      .selectFilter:nth-child(1) {
        border-radius: unset;
      }

      .selectFilter:nth-child(3) {
        border-radius: unset;
      }
    }
  }
}

@keyframes btnFilterOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes openFilterMobile {
  from {
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 10px;
  }
  to {
    width: 95%;
    bottom: 10px;
    right: 10px;
    height: 200px;
  }
}

@keyframes closeFilterMobile {
  from {
    width: 95%;
    bottom: 10px;
    right: 10px;
    height: 200px;
  }
  to {
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 10px;
  }
}

@keyframes inputOn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inputOff {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
