.myAccountPartner {
  .containerAccountPartner {
    padding-left: 400px;
    padding-top: 20px;

    .containerformAccountPartner {
      width: 100%;
      margin-top: 5%;

      .formAccountPartner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
