.button-normal {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #587e61;
  border: none;
  color: #ffff;
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.button-normal:hover {
  background-color: #f4de7e;
  color: #587e61;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.button-variant {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #f4de7e;
  border: none;
  color: #587e61;
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.button-variant:hover {
  cursor: pointer;
}

.button-delete {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #ce736d;
  border: none;
  color: #ffff;
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.button-delete:hover {
  cursor: pointer;
}

.button-noHover {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #587e61;
  border: none;
  color: #ffff;
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.button-noHover:hover {
  cursor: pointer;
}
