.sectionAdminConcept {
  padding-left: 400px;
  padding-top: 20px;
}

.containerFormConcept {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.zoneTextConcept {
  width: 100%;
  height: 300px;
  padding: 20px 0;
  font-family: "Manrope", sans-serif;
  padding-bottom: 75px;
}

.zoneTextConcept h1 {
  font-family: "Amatic SC", cursive;
  text-align: center;
}

.zoneTextConcept h2 {
  font-family: "Manrope", cursive;
  font-weight: 600px;
  margin-left: 50px;
}

.zoneTextConcept h3 {
  font-family: "Manrope", cursive;
  font-weight: 300px;
  margin-left: 25px;
}

.btnModifConcept {
  width: 250px;
  height: 40px;
  background-color: var(--principale);
  color: white;
  border: none;
  font-size: 18px;
  margin-bottom: 30px;
}

.btnModifConcept:hover {
  width: 250px;
  height: 40px;
  background-color: var(--bouton);
  color: var(--principale);
  font-size: 18px;
  cursor: pointer;
}
