.inputImage-label {
  border: solid 1px #587e61;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  color: #587e61;
  font-family: "Manrope", sans-serif;
  font-size: inherit;
}

.inputImage-input {
  display: none;
}
