.blogAdminGlobal {
  padding-left: 400px;
  padding-top: 20px;
}

.blogAdminBtnPlus {
  border: solid 2px var(--principale);
  font-family: inherit;
  font-size: inherit;
  background-color: inherit;
  margin: 10px 0;
}

.blogAdminBtnPlus:hover {
  cursor: pointer;
}

.containerAddNewArticle {
  width: 90%;
  margin-top: 30px;
}

.inputNewTitle {
  height: 40px;
  width: 300px;
  margin: 10px 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.textAddImage {
  margin: 20px 10px 10px 10px;
}

.inputNewImage {
  height: 40px;
  width: 500px;
  margin: 10px 0;
  font-size: inherit;
}

.containerQuill {
  height: 300px;
}

.inputNewArticle {
  height: 250px;
  margin: 10px 0;
}

.keywordTag {
  margin: 10px;
  padding: 5px 10px;
  border: solid 1px var(--principale);
  border-radius: 25px;
  font-family: inherit;
  color: inherit;
}

.containerAllKeyword {
  position: relative;
}

.inputKeyword {
  height: 40px;
  width: 300px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.containerSuggestKeyword {
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 1000;
  width: 300px;
  background-color: white;
  border: solid 1px var(--principale);
}

.suggestKeyword {
  margin: 5px;
  padding: 5px 10px;
  border: solid 1px var(--principale);
  border-radius: 25px;
  color: inherit;
  font-family: inherit;
}

.suggestKeyword:hover {
  cursor: pointer;
}

.BtnAddKeyword {
  background-color: var(--principale);
  color: var(--bouton);
  padding: 5px 25px;
  border: none;
  height: 40px;
}

.BtnAddKeyword:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.containerBtnAddArticle {
  display: flex;
  justify-content: center;
}

.btnAddArticle {
  background-color: var(--principale);
  color: var(--bouton);
  padding: 5px 25px;
  border: none;
  height: 40px;
}

.btnAddArticle:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.containerMapArticles {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerArticle {
  border: solid 2px var(--principale);
  border-radius: 25px;
  width: 70%;
  margin: 20px;
}

.dateAndKeyword {
  display: flex;
  justify-content: space-between;
}

.dateArticle {
  margin: 10px;
  color: var(--principale);
}

.containerKeywords {
  display: flex;
}

.listKeywords {
  color: var(--principale);
  margin: 10px;
  right: 10px;
}

.imgArticle {
  width: 100%;
}

.titreBlog {
  text-align: center;
  color: var(--principale);
  margin: 10px;
}

.containerTextArticle {
  margin: 10px;
  max-height: 100px;
  overflow-y: hidden;
}

.containerTextArticle li {
  margin-left: 50px;
}

.containerTextArticle h1 {
  font-family: "Amatic SC", cursive;
  font-weight: 800px;
  text-align: center;
  font-size: 45px;
  margin: 20px 0;
}

.containerTextArticle h2 {
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin: 20px 50px;
}

.containerTextArticle h3 {
  font-family: "Manrope", sans-serif;
  margin: 20px 25px;
}

.ql-snow .ql-editor li {
  margin-left: 50px;
}

.ql-snow .ql-editor h1 {
  font-family: "Amatic SC", cursive;
  text-align: center;
  font-size: 45px;
  margin: 20px 0;
}

.ql-snow .ql-editor h2 {
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin: 20px 50px;
  font-size: 28px;
}

.ql-snow .ql-editor h3 {
  font-family: "Manrope", sans-serif;
  margin: 20px 25px;
  font-size: 18px;
}

.containerBtnModify {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.BtnModifyArticle {
  background-color: var(--principale);
  color: var(--bouton);
  padding: 5px 25px;
  border: none;
  height: 40px;
  width: 150px;
  margin: 10px;
}

.BtnModifyArticle:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.backgroundBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: white;
}

.containerModificationArticle {
  position: fixed;
  z-index: 2;
  top: 10%;
  max-height: 90%;
  left: 12%;
  width: 76%;
  height: auto;
  background-color: white;
  border: solid 2px var(--principale);
  padding: 10px;
  overflow: scroll;
}

.BtnDeleteArticle {
  background-color: var(--principale);
  color: var(--bouton);
  padding: 5px 25px;
  border: none;
  height: 40px;
  width: 150px;
  margin: 10px;
}

.BtnDeleteArticle:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.containerModifyArticle {
  width: 100%;
  margin-top: 50px;
}

.modifyArticle {
  width: auto;
}

.containerMapImagesModify {
  display: flex;
  justify-content: center;
}

.containerImagesModify {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.imgArticleToModify {
  max-width: 250px;
  max-height: 250px;
  margin: 10px;
}

.btnImgArticleToModify {
  border: none;
  background-color: var(--principale);
  font-size: large;
  color: var(--bouton);
  padding: 5px 25px;
}

.btnImgArticleToModify:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}
