.handleOptionsAdmin {
  display: flex;
  flex-direction: column;
  align-items: center;

  .containerMenuOption {
    text-align: center;
    margin: 50px 0 30px 0;
  }

  .insidePopup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containerOptions {
    display: flex;

    .optionSelected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      height: 60px;
      border: solid 1px var(--principale);
      margin: 10px;
      padding: 5px;
    }
  }
}
