.quill-container {
  display: flex;
  justify-content: center;
}

.quill-display {
  width: 70%;
  height: 100%;
  text-align: center;
}

.ql-container {
  font-size: 18px;
  font-family: inherit;
  color: inherit;
}
