.sectionInputs {
  margin-top: -30px;
  z-index: 2;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
}

.inputsClose {
  display: flex;
  justify-content: center;
  font-family: inherit;
  color: var(--principale);
  font-size: 20px;
  width: 100%;
}

.containerCalendarInput {
  display: flex;
  flex-direction: column;
  width: 14%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.inputDates {
  font-family: inherit;
  color: var(--principale);
  background-color: white;
  width: 100%;
  height: 100%;
  font-size: 18px;
  border-radius: 10px 0 0 10px;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  border: solid 1px var(--principale);
}

.inputDates:hover {
  background-color: var(--police);
  cursor: pointer;
}

.displayCalendar {
  position: absolute;
  top: 60px;
}

.inputParc::placeholder {
  color: var(--principale);
  text-align: center;
}

.inputParc {
  font-family: inherit;
  color: var(--principale);
  text-align: center;
  text-align-last: center;
  background-color: white;
  font-size: 18px;
  height: 100%;
  border: solid 1px var(--principale);
  width: 14%;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 2;
}

.inputParc::-ms-expand {
  display: none;
}

.inputHebergement {
  font-family: inherit;
  color: var(--principale);
  text-align: center;
  text-align-last: center;
  background-color: white;
  font-size: 18px;
  height: 100%;
  border: solid 1px var(--principale);
  width: 14%;
  cursor: pointer;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  -webkit-appearance: none;
  z-index: 2;
}

.inputHebergement::-ms-expand {
  display: none;
}

.inputNbPersonnes {
  font-family: inherit;
  color: var(--principale);
  text-align: center;
  text-align-last: center;
  background-color: white;
  font-size: 18px;
  height: 100%;
  border: solid 1px var(--principale);
  width: 14%;
  cursor: pointer;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  -webkit-appearance: none;
  z-index: 2;
}

.inputNbPersonnes::-ms-expand {
  display: none;
}

.rechercher {
  font-family: inherit;
  color: var(--bouton);
  height: 100%;
  width: 14%;
  text-align: center;
  font-size: 18px;
  background-color: var(--principale);
  border: none;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  border-radius: 0 10px 10px 0;
  z-index: 2;
}

.rechercher2 {
  display: none;
}

.rechercher:hover {
  background-color: var(--bouton);
  font-weight: bold;
  color: var(--principale);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.rechercherLink {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-color: transparent;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 18px;
  background-color: var(--principale);
  color: white;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 0 10px 10px 0;
  z-index: 2;
}

.rechercherLink:hover {
  color: var(--principale);
  background-color: var(--bouton);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sectionInputs {
    margin-top: -25px;
    height: 50px;
  }

  .inputDates {
    font-size: 12px;
  }

  .displayCalendar {
    top: 50px;
  }

  .inputParc {
    font-size: 12px;
  }

  .inputHebergement {
    font-size: 12px;
  }

  .inputNbPersonnes {
    font-size: 12px;
  }

  .rechercher {
    font-size: 12px;
  }

  .rechercher:hover {
    background-color: var(--bouton);
    color: var(--principale);
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
  }
  .rechercherLink {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sectionInputs {
    background-color: var(--principale);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: unset;
    padding-top: 25px;
    margin-top: 0;
    position: relative;
  }

  .inputsClose {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 90px;
    left: -600px;
    opacity: 0;
    background-color: var(--principale);
    transition: all 0.5s ease-in-out;
  }

  .inputsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 90px;
    left: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    background-color: var(--principale);
  }

  .containerCalendarInput {
    width: 100%;
    z-index: 3;
  }

  .displayCalendar {
    left: 3%;
    right: 3%;
  }

  .inputDates {
    font-size: 18px;
    border-radius: 0px;
    height: 60px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 30px;
    text-align: center;
    text-align-last: center;
  }

  .inputHebergement {
    font-size: 18px;
    height: 60px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 30px;
    text-align: center;
    text-align-last: center;
  }

  .inputNbPersonnes {
    font-size: 18px;
    border-radius: 0px;
    height: 60px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 30px;
    text-align: center;
    text-align-last: center;
  }
  .inputParc {
    font-size: 18px;
    border-radius: 0px;
    height: 60px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 30px;
    text-align: center;
    text-align-last: center;
  }
  .rechercher {
    font-family: inherit;
    color: var(--principale);
    background-color: var(--autre);
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    border-radius: 0;
    height: 50px;
    outline: none;
    border-color: transparent;
    margin: 0 0 25px 0;
    border: none;
    width: 180px;
    display: flex;
    justify-content: center;
  }

  .rechercher:hover {
    background-color: var(--bouton);
    color: var(--principale);
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }

  .rechercherLink {
    display: flex;
    justify-content: center;
    text-decoration: none;
    background-color: var(--autre);
    color: var(--principale);
    height: 50px;
    width: 180px;
    align-items: center;
    border-radius: 0;
  }

  .rechercherLink:hover {
    background-color: var(--bouton);
    color: var(--principale);
  }

  .rechercher2 {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .rechercherLink2 {
    background-color: var(--autre);
    color: var(--principale);
    height: 40px;
    width: 140px;
    border-radius: 0;
    box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
    border-color: transparent;
    text-transform: uppercase;
    font-family: inherit;
  }
}
