.calendarPartner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btnDivCalLink {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .containerCalendar {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;
    min-width: 800px;
    margin: 30px 0;
  }
}
