.modifyOptionAdmin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  .labelCheckbox {
    display: flex;
    margin: 30px 0;

    & p {
      margin-right: 10px;
    }
  }

  .containerImgOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px var(--principale);
    padding: 10px;
    margin: 10px;
    position: relative;

    .imgOption {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }

    .labelNewImgOption {
      font-size: 1.75em;
      cursor: pointer;

      .inputNewImg {
        display: none;
      }
    }

    .hoverBtn {
      visibility: hidden;
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 100%;
      font-size: initial;
      display: flex;
      justify-content: center;
      color: var(--police);
      background-color: var(--principale);
    }

    .labelNewImgOption:hover {
      & .hoverBtn {
        visibility: visible;
      }
    }
  }
}
