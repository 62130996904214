.navbar {
  background-color: var(--principale);
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.03rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  color: var(--bouton);
}
.leptitlogo {
  margin-left: 0.5rem;
  font-size: 1.8rem;
  width: 80px;
  height: 80px;
}

.leptitlogo:hover {
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: scale(1.2);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-itemBtn {
  height: 80px;
  border: none;
  background-color: initial;
  font-size: inherit;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  cursor: pointer;
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.nav-links2 {
  color: var(--principale);
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: var(--autre);
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links2:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1024px) {
  .cart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    width: 25px;
    position: relative;
    right: 5rem;
  }

  .basket {
    cursor: pointer;
    height: 25px;
    width: 25px;
    color: #fff;
  }

  .buy-list {
    color: #fff;
    position: absolute;
    font-weight: 600;
    top: 0;
    left: 0.55em;
  }

  .is-not-empty {
    color: var(--bouton);
  }

  .check-basket {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 10%;
    left: 0;
    background-color: var(--police);
  }
}

@media screen and (max-width: 767px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .activeNavMenu {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    left: 0;
    opacity: 1;
    // background-color: var(--principale);
    transition: all 0.5s ease;
  }

  .nav-item {
    min-height: 80px;
    height: auto;
    background-color: var(--principale);
  }

  .nav-itemBtn {
    min-height: 80px;
    height: auto;
    border: none;
    background-color: var(--principale);
    font-size: inherit;
    opacity: inherit;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
  .nav-links2 {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links2:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    // padding-left: -30px;
    font-size: 1.5rem;
    margin-left: -20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    background-color: inherit;
    outline: 0;
    border: none;
    top: 25px;
    right: 25px;
    font-size: 1.8rem;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  .fa-times {
    color: #fff;
    // transform: rotate(180deg);
    background-color: inherit;
    border: none;
    width: 100%;
    height: 100%;
  }

  .fa-bars {
    // transform: rotate(-180deg);
    background-color: inherit;
    border: none;
    // outline: 0;
    width: 100%;
    height: 100%;
  }

  .fa-times:active {
    background-color: inherit;
    border: none;
    outline: 0;
  }
}
