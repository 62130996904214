.contientParc {
  margin: 40px 0 20px 0;
  width: 100%;

  .zoneTextDuParc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.contientParcHidden {
  margin: 40px 0 20px 0;
  width: 100%;

  .zoneTextDuParc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    padding-left: 15%;
    padding-right: 15%;

    .descriptionParc {
      p,
      h1,
      h2,
      h3,
      li {
        display: none;
      }

      p:nth-child(1) {
        display: block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.containerCarouselAndTitle {
  width: 100%;
  height: 100%;
  position: relative;
}

.containerParcTitle {
  position: absolute;
  width: 100%;
  height: 100%;
}

.parcTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.titreDuParc {
  z-index: 800;
  width: auto;
  border: 3px solid white;
  box-shadow: 2px 2px 2px black;
  padding: 10px 50px;
}

.containerBtnReadMore {
  width: 100%;
  display: flex;
  padding-left: 15%;
  margin-bottom: 60px;
}

.btnReadMoreParc {
  border: none;
  font-size: inherit;
  background-color: inherit;
  color: var(--principale);
}

.btnReadMoreParc:hover {
  cursor: pointer;
  // background-color: var(--bouton);
}

.paraphDuParc li {
  margin-left: 50px;
}

.paraphDuParc h1 {
  font-family: "Amatic SC", cursive;
  font-weight: 800px;
  margin-left: 100px;
}

.paraphDuParc h2 {
  font-family: "Manrope", cursive;
  font-weight: 600px;
  margin-left: 50px;
}

.paraphDuParc h3 {
  font-family: "Manrope", cursive;
  font-weight: 300px;
  margin-left: 25px;
}

.zoneTrait {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.trait {
  border-bottom: 1px solid var(--principale);
  text-align: center;
  width: 60%;
}
.containerHebergement {
  display: block;
  width: 100%;
  margin: 50px 0 40px 0;
  padding: 0 15%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerHebergement {
    display: block;
    width: 100%;
    margin: 50px 0 0 0;
    padding-left: 0%;
    padding-right: 0%;
  }

  .nosHebergements {
    // font-family: "Amatic SC", sans-serif;
    // font-size: 40px;
    margin-bottom: 10px;
    // color: var(--principale);
    // text-align: center;
  }

  .trait {
    border-bottom: 1px solid var(--principale);
    text-align: center;
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .nosHebergements {
    // font-size: 35px;
    margin-bottom: 10px;
    // color: var(--principale);
    // text-align: center;
  }

  .contientParc {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    width: 100%;
    margin-bottom: 30px;
  }

  .zoneTextDuParc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 10%;
  }

  .containerBtnReadMore {
    padding-left: 10%;
  }

  .containerHebergement {
    padding: 0 5%;
  }

  .paraphDuParc {
    font-size: 15px;
  }
}
