.plusMinusButtons-containerBtns {
  display: flex;
  align-items: center;
}

.plusMinusButtons-addRemoveBtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #587e61;
  background-color: inherit;
  margin: 0 10px;
  color: #587e61;
}

.plusMinusButtons-addRemoveBtn:hover {
  cursor: pointer;
}
