.sectionInfosPratiques {
  width: 100%;
  height: auto;
}

.containerInfosText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;
}

.containerOneSection {
  width: 70%;
  padding-top: 80px;
}

.titreInfosPratiques {
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerOneSection {
    width: 80%;
  }
}

@media screen and (max-width: 670px) {
  .containerOneSection {
    width: 90%;
  }
}
