.calendrierPro {
  .containerCalendrier {
    padding-left: 400px;
    padding-top: 20px;

    .containerCards {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .cardAccomodation {
        width: 320px;
        background-color: var(--autre);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px;
        padding: 20px;
        cursor: pointer;
      }
    }
  }
}
