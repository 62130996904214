.tilesColor {
  background-color: var(--principale);
}

.tilesAfterColor {
  background-color: #bfcfcb;
}

.defaultTile {
  background-color: rgb(252, 252, 252);
}

.today {
  background-color: #ffff76;
  color: black;
}
