.containerPreviewCalendar {
  .selectedTile {
    width: 350px;

    .previewBoxHead {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .buttons {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .descriptionBooking {
        display: flex;

        & p:first-child {
          margin-right: 5px;
        }
      }
    }

    .containerModify {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
