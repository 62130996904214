.modifyParc {
  .infoParc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .containerParcImages {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .containerOneImg {
      height: auto;
      border: solid 1px var(--principale);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px;

      .parcImg {
        width: 250px;
        height: 250px;
        margin: 10px;
        object-fit: cover;
      }
    }
  }
}
