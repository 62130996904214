.containerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 100%;
  background-color: var(--bouton);
}

.logo {
  height: 250px;
  border: solid 2px var(--principale);
}

.containerText {
  width: 500px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--principale);
}

.text {
  font-family: "amatic SC", sans-serif;
  font-size: 100px;
  color: var(--principale);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerHeader {
    height: 250px;
  }

  .logo {
    height: 150px;
  }

  .containerText {
    height: 150px;
    width: 350px;
  }
}

@media screen and (max-width: 767px) {
  .containerHeader {
    height: 150px;
  }

  .logo {
    display: none;
  }

  .containerText {
    border: none;
    height: 50px;
    width: 250px;
  }
}
