.handleCalendarAdmin {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .cardAccomodationCalendar {
    width: 320px;
    background-color: var(--autre);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    padding: 20px;
    cursor: pointer;
  }
}
