.AddRoomAccomodation {
  .containerBtnAddRoom {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .formAddRoom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
  }
}
