.flexPos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.buttonCard {
  margin: 30px;
  text-align: center;
}
.alignTitle {
  text-align: center;
}

.buttonGlobalDisabled {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: var(--disableBtn);
  border: none;
  color: var(--police);
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.buttonGlobalDisabled:hover {
  color: var(--bouton);
  transition: all 0.3s ease-out;
  cursor: not-allowed;
}
