.containerGlobalParc {
  height: 500px;
}

.containerImagesMapParc {
  position: relative;
  width: 100%;
  height: 100%;
}

.containerImagesMapParc img {
  object-fit: cover;
  position: absolute;
  transition: opacity 1s ease-in-out;
}

.activeParc {
  width: 100%;
  height: 500px;
  opacity: 0.7;
}

.unactiveParc {
  width: 100%;
  height: 500px;
  opacity: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerGlobalParc {
    height: 350px;
  }

  .containerImagesMapParc {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .containerImagesMapParc img {
    position: absolute;
    transition: opacity 1s ease-in-out;
  }

  .activeParc {
    width: 100%;
    height: 350px;
    opacity: 0.7;
  }

  .unactiveParc {
    width: 100%;
    height: 350px;
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .containerGlobalParc {
    height: 300px;
  }

  .containerImagesMapParc {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .containerImagesMapParc img {
    position: absolute;
    transition: opacity 1s ease-in-out;
  }

  .activeParc {
    width: 100%;
    height: 300px;
    opacity: 0.7;
  }

  .unactiveParc {
    width: 100%;
    height: 300px;
    opacity: 0;
  }
}
