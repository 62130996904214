.accomodationAdminFiltre {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .formFiltre {
    display: flex;
    align-items: center;

    .LabelFiltre {
      display: flex;
      align-items: center;
      margin: 0 15px;

      .filtreCheckbox {
        margin-right: 15px;
      }
    }
  }
}
