.RoomListAdmin {
  .list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .oneRoom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 500px;
      border: solid 1px var(--principale);
      margin: 10px;

      & p {
        margin-left: 5px;
      }
    }
  }
}
