.containerHeaderContact {
  width: 100%;
  height: 500px;
}

.sectionHeaderContact {
  background: url("../../../../Assets/imageHeaderContact.jpg") center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerHeaderContact {
    height: 350px;
  }
}

@media screen and (max-width: 767px) {
  .containerHeaderContact {
    height: 300px;
  }

  .titreHeaderContact {
    text-align: center;
  }
}
