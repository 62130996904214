.addButton-button {
  border: solid 2px #587e61;
  font-family: inherit;
  font-size: inherit;
  background-color: inherit;
  margin: 10px 0;
}

.addButton-button:hover {
  cursor: pointer;
}

.addButton-button > .large {
  font-family: "manrope", sans-serif;
  font-size: 27px;
  font-weight: 900;
  padding: 15px;
  color: #587e61;
  margin: unset;
}

.addButton-button > .small {
  font-family: "manrope", sans-serif;
  font-weight: bold;
  padding: 10px;
  color: #587e61;
  margin: unset;
}
