.popupMessage-open {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255, 0.7);
  z-index: 9998;
}

.popupMessage-containerMessage {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 39%;
  left: 35%;
  width: 30%;
  height: 22%;
  min-height: 75px;
  border: solid 1px #587e61;
  border-radius: 20px;
  background-color: #ffff;
}

.popupMessage-messageText {
  color: #587e61;
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 400;
}

.popupMessage-none {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .popupMessage-containerMessage {
    left: 20%;
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .popupMessage-containerMessage {
    left: 5%;
    width: 90%;
  }
}
