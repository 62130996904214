.containerListCities {
  position: absolute;
  top: 40px;
  background-color: var(--autre);
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  overflow-y: scroll;
  max-height: 200px;

  .listCities:hover {
    background-color: var(--bouton);
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
}
