.lesHebergements {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .UnHebergement {
    display: flex;
    flex-direction: column;
    background-color: var(--autre);
    width: 30%;
    height: 350px;
    margin: 10px;
    position: relative;

    .zoneImage1Hebergement {
      text-align: center;
      width: 100%;
      height: 220px;
      min-height: 75%;
      padding: 25px 25px 0 25px;

      .testImage {
        width: 90%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      .testImage:hover {
        cursor: pointer;
        transition: all 0.3s ease-out;
        transform: scale(1.1);
      }
    }

    .zoneText1Hebergement {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 60px;
      max-height: 120px;
      padding: 0 25px;

      .titreDeChaqueHebergement {
        margin: 10px 0;
        text-align: center;
        width: 80%;
      }
    }

    .containerPrice {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background-color: var(--principale);

      .priceP:nth-child(1),
      .priceP:nth-child(3) {
        color: var(--police);
        font-weight: 400;
      }

      .priceP:nth-child(2) {
        font-family: "Amatic SC", cursive;
        font-size: 35px;
        padding: 0 8px;
        font-weight: bold;
        color: var(--police);
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .lesHebergements {
    .UnHebergement {
      .zoneImage1Hebergement {
        height: 175px;

        .testImage {
          width: 100%;
          filter: none;
        }

        .testImage:hover {
          filter: none;
          transform: none;
        }
      }

      .zoneText1Hebergement {
        .titreDeChaqueHebergement {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .lesHebergements {
    flex-direction: column;
    width: 100%;

    .UnHebergement {
      width: 100%;
      margin: 10px 0;

      .zoneImage1Hebergement {
        .testImage {
          filter: none;
        }

        .testImage:hover {
          filter: none;
          transform: none;
        }
      }
    }

    .zoneText1Hebergement {
      height: 65%;
    }
  }
}
