.container404 {
  display: flex;
}

.block_err {
  display: flex;
  background: url("../../../Assets/background.jpg");
  flex-direction: column;
  background-size: cover;
  height: 90vh;
  width: 100vw;
}

.background_glasmo {
  display: flex;
  flex-direction: column;
  margin: 15% 10% 10% 10%;
  background-color: var(--glasmoBg);
  border-radius: 10px;
}

.Titre_404 {
  align-items: center;
  margin: 5% auto;
  font-weight: 400;
  font-size: 120px;
  line-height: 100px;
  color: var(--bouton);
  text-shadow: 0px 4px 4px #587e61;
  font-family: "Amatic SC", cursive;
}

.Paragraphe_err {
  width: 70vw;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: var(--bouton);
  text-shadow: 0px 2px 2px #4f4f4f;
  margin: 10vh 5vw;
}

.but_acceuil {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;
  margin: 10vh auto;
  background: --(color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.44);
  border-radius: 10px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 500px;
  color: #ffffff;
  text-decoration: none;
  border: none;
}

@media screen and (min-width: 700px) {
  .background_glasmo {
    margin: 2% 20%;
  }
  .Paragraphe_err {
    font-size: 26px;
    line-height: 5vh;
    width: 60%;
    margin: 5vw auto;
    text-align: center;
  }

  .block_err {
    width: 80vw;
    margin: 5vh auto;
    border-radius: 10px;
    filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.53));
  }

  .but_acceuil {
    margin: auto;
  }
}

@media screen and (max-height: 500px) and (max-width: 900px) {
  .block_err {
    height: 550px;
  }

  .Paragraphe_err {
    line-height: 30px;
  }
}
