.btnBackAllBlog {
  position: fixed;
  top: 100px;
  left: 20px;
  display: flex;
  text-decoration: none;
  color: var(--principale);
  border: solid 1px var(--principale);
  padding: 5px 15px;

  .arrowBackAllBlog {
    padding-right: 10px;
  }
}

.containerGlobalArticle {
  display: flex;
  justify-content: center;

  .containerBlogArticle {
    width: 60%;
    margin-top: 50px;
    margin-bottom: 100px;

    .dateAndKeywords {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      font-family: "manrope", sans-serif;

      .dateBlogArticle {
        color: var(--principale);
        margin: 0 5px;
        display: flex;
        align-items: center;
      }

      .containerBlogKeyword {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        .blogKeywords {
          color: var(--principale);
          margin: 0 5px;
        }
      }
    }

    .containerBlogImg {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      .imageBlogArticle {
        width: 100%;
      }
    }

    .articleBlogComplet {
      height: auto;
    }

    .articleBlogComplet li {
      margin-left: 50px;
    }

    .articleBlogComplet h1 {
      font-family: "Amatic SC", cursive;
      font-weight: 800px;
      text-align: center;
      font-size: 45px;
      margin: 20px 0;
    }

    .articleBlogComplet h2 {
      font-family: "Manrope", cursive;
      text-align: center;
      margin: 20px 50px;
    }

    .articleBlogComplet h3 {
      font-family: "Manrope", cursive;
      margin: 20px 25px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btnBackAllBlog {
    .arrowBackAllBlog {
      padding-right: unset;
    }

    .textBackAllBlog {
      display: none;
    }
  }

  .containerGlobalArticle {
    .containerBlogArticle {
      width: 80%;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .btnBackAllBlog {
    display: none;
  }

  .containerGlobalArticle {
    .containerBlogArticle {
      width: 90%;
      margin-top: 25px;

      .articleBlogComplet li {
        margin-left: 25px;
      }

      .articleBlogComplet h1 {
        font-family: "Amatic SC", cursive;
        font-weight: 800px;
        text-align: center;
        font-size: 30px;
        margin: 20px 0;
      }

      .articleBlogComplet h2 {
        font-family: "Manrope", cursive;
        text-align: center;
        margin: 20px 0;
        font-size: 20px;
      }

      .articleBlogComplet h3 {
        font-family: "Manrope", cursive;
        margin: 20px 0;
        font-size: 20px;
      }
    }
  }
}
