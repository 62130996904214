.closeButton-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid 1px #587e61;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: inherit;
  font-family: "manrope", sans-serif;
  color: #587e61;
}

.closeButton-button:hover {
  cursor: pointer;
  border: solid 1px #bfcfcb;
}
