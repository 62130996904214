.header-modale {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80px;
  width: 100%;
  position: sticky;
  background-color: var(--bouton);
}

.close-cross {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 20px;
  cursor: pointer;
  grid-column: 2;
  margin: auto;
}
