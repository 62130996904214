.containerKeywords {
  display: flex;
  justify-content: center;

  .containerBlogKeys {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .blogKeyword {
      margin: 10px;
      border: none;
      font-size: 18px;
      background-color: inherit;
      font-family: "Manrope", sans-serif;
      color: var(--principale);
      font-weight: 200;
    }

    .blogKeyword:hover {
      cursor: pointer;
    }
  }
}
