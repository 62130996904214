.containerPreorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .containerCp {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .inputMessage {
    width: 60%;
    border-radius: 5px;
  }
}
