.containerHeaderConcept {
  width: 100%;
  height: 500px;
}

.sectionHeaderConcept {
  background: url("../../../../Assets/imageHeaderConcept.jpg") center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoPartirHome {
  width: 300px;
  height: 300px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerHeaderConcept {
    width: 100%;
    height: 350px;
  }

  .logoPartirHome {
    width: 210px;
    height: 210px;
  }
}

@media screen and (max-width: 767px) {
  .containerHeaderConcept {
    width: 100%;
    height: 350px;
  }

  .logoPartirHome {
    width: 210px;
    height: 210px;
  }
}
