.globalCart {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btnBookingDetails {
    background-color: inherit;
    border: none;

    .questionMark {
      display: none;
    }
  }

  .containerCart {
    width: 80%;
    background-color: var(--autre);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;

    .dateBooking {
      text-align: center;
      margin: 15px 0;
      width: 80%;

      h3 {
        margin-bottom: 5px;
      }

      .recapBookingFinalPrice {
        font-weight: bold;
        font-size: 25px;
      }
    }

    .dividerBooking {
      width: 50%;
      height: 1px;
      background-color: var(--principale);
      margin: 10px 0;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .globalCart {
    .containerCart {
      width: 90%;
    }
  }
}

@media screen and (max-width: 767px) {
  .globalCart {
    .btnBookingDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .questionMark {
        display: block;
        font-size: 15px;
        font-family: inherit;
        border: solid 1px var(--principale);
        border-radius: 50px;
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }

    .containerCartClose {
      display: none;
    }

    .containerCart {
      width: 100%;
    }
  }
}
