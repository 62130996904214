.profilAdminGlobal {
  padding-left: 350px;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.profilAdminForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilAdminLabel {
  margin: 15px;
  min-width: 350px;
  height: 35px;
}

.profilAdminLabel input {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: inherit;
  color: inherit;
}

.profilAdminBtn {
  margin: 15px;
  width: 50%;
  height: 35px;
  border: none;
  background-color: var(--principale);
  color: var(--police);
}

.profilAdminBtn:hover {
  margin: 15px;
  width: 50%;
  height: 35px;
  border: none;
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
}
