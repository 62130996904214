.listePackageGlobal {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .containerGlobalMap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--autre);
    width: 30%;
    height: auto;
    margin: 10px;
    position: relative;

    .containerImagePackage {
      text-align: center;
      width: 100%;
      height: 220px;
      padding: 25px 25px 0 25px;

      .imagePackage {
        width: 90%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      .imagePackage:hover {
        transition: all 0.3s ease-out;
        transform: scale(1.1);
      }
    }

    .containerNomPackage {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 25px;
      min-height: 60px;
      max-height: 120px;

      .nomPackage {
        text-align: center;
        width: 80%;
      }
    }

    .containerPrice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80px;
      background-color: var(--principale);

      .priceP:nth-child(1) {
        color: var(--police);
        font-weight: 400;
      }

      .priceP:nth-child(2) {
        font-family: "Amatic SC", cursive;
        font-size: 35px;
        padding: 0 8px;
        font-weight: bold;
        color: var(--police);
      }
    }

    .containerGlobalDate {
      padding: 15px 25px 10px 25px;
      min-height: 60px;
      max-height: 120px;

      .nextDate {
        text-align: center;
      }

      .containerDate {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }

      .dateDispo:nth-child(n + 5) {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .listePackageGlobal {
    .containerGlobalMap {
      .containerImagePackage {
        height: 175px;

        .imagePackage {
          width: 100%;
          filter: none;
        }

        .imagePackage:hover {
          filter: none;
          transform: none;
        }
      }

      .containerNomPackage {
        .nomPackage {
          width: 100%;
        }
      }

      .containerPrice {
        flex-direction: column;
        align-items: center;
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .listePackageGlobal {
    flex-direction: column;
    width: 100%;

    .containerGlobalMap {
      width: 100%;
      margin: 10px 0;

      .containerImagePackage {
        .imagePackage {
          filter: grayscale(0%);
        }

        .imagePackage:hover {
          transition: unset;
          transform: unset;
          filter: unset;
        }
      }

      .containerPrice {
        flex-direction: column;
        align-items: center;
        height: 100px;
      }
    }
  }
}
