.popupContainer-bgBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.popupContainer-popupOpen {
  display: block;
  position: fixed;
  background-color: #ffff;
  z-index: 2;
  border: 1px solid #587e61;
  padding: 15px;
  overflow-y: auto;
}
