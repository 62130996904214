.containerGlobalAdminPackage {
  padding-left: 400px;
  padding-top: 20px;

  .containerAddPackage {
    width: 90%;

    .containerFormAdminPackage {
      display: flex;
      flex-direction: column;
      align-items: center;

      .containerSelect {
        margin-bottom: 30px;
      }

      .formAdminPackage {
        width: 300px;
        height: 40px;
        margin: 10px;

        .inputAdminPackage {
          width: 100%;
          height: 100%;
          text-align: center;
          color: var(--principale);
          font-family: inherit;
          background-color: inherit;
          border: solid 1px var(--principale);
          border-radius: 3px;
          font-size: inherit;
        }
      }

      .quillAdminPackage {
        width: 100%;
        height: 150px;
        margin: 10px 0;
        padding-bottom: 50px;

        li {
          margin-left: 50px;
        }

        h1 {
          font-family: "Amatic SC", cursive;
          font-weight: 800px;
          margin-left: 100px;
        }

        h2 {
          font-family: "Manrope", cursive;
          font-weight: 600px;
          margin-left: 50px;
        }

        h3 {
          font-family: "Manrope", cursive;
          font-weight: 300px;
          margin-left: 25px;
        }
      }

      .containerAddImg {
        border: solid 1px var(--principale);
        border-radius: 3px;
        width: 300px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        cursor: pointer;

        .addImg {
          display: none;
        }
      }

      .adminPackageBtnSubmit {
        border: none;
        background-color: var(--principale);
        color: var(--police);
        width: 150px;
        height: 35px;
        margin: 10px;
      }

      .adminPackageBtnSubmit:hover {
        cursor: pointer;
        background-color: var(--bouton);
        color: var(--principale);
      }
    }
  }

  .packageAdminList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    border: solid 2px var(--principale);
    margin: 5px 0;

    .packageAdminListName {
      margin: 10px;
    }

    .packageAdminBtnModify {
      border: none;
      background-color: var(--principale);
      color: var(--police);
      width: 150px;
      height: 35px;
      margin: 10px;
    }

    .packageAdminBtnModify:hover {
      background-color: var(--bouton);
      color: var(--principale);
      cursor: pointer;
    }
  }
}
