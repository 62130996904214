.parcAdminGlobal {
  padding-left: 400px;
  padding-top: 20px;
}

.parcAdminBtnPlus {
  border: solid 2px var(--principale);
  font-family: inherit;
  font-size: inherit;
  background-color: inherit;
  margin: 10px 0;
}

.parcAdminBtnPlus:hover {
  cursor: pointer;
}

.parcAdminContainerAdd {
  position: relative;
}

.parcAdminContainerLabel {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
}

.parcAdminLabel {
  width: 90%;
}

.parcAdminLabelDes {
  width: 90%;
  height: 250px;
}

.parcAdminNewName {
  width: 30%;
  height: 35px;
  text-indent: 5px;
  margin: 10px 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.parcAdminNewDescription {
  width: 100%;
  height: 200px;
  margin: 10px 0;
  font-family: inherit;
  font-size: inherit;
}

.parcAdminTitleUploadImage {
  padding: 15px;
}

.parcAdminLabelUploadImg {
  position: relative;
  width: 90%;
  margin: 10px 0;
  display: inline;
}

.parcAdminLabelUploadImg input {
  height: 35px;
  vertical-align: middle;
  text-align: center;
  font-family: inherit;
  color: inherit;
}

.parcAdminInputUploadDescription {
  position: absolute;
  right: 40%;
  height: 35px;
}

.parcAdminContainerUploadImg {
  display: flex;
  justify-content: center;
  width: 90%;
}

.parcAdminContainerMapModify {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.parcAdminContainerImgModify {
  height: 280px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border: solid 1px var(--principale);
  margin: 5px;
}

.containerBtnDeleteImg {
  position: absolute;
  bottom: 10px;
}

.parcAdminImgModify {
  max-width: 250px;
  max-height: 250px;
  margin: 10px;
}

.parcAdminBtnImgModify {
  position: absolute;
  bottom: 10px;
  border: none;
  background-color: var(--principale);
  font-size: large;
  color: var(--bouton);
  padding: 5px 25px;
}

.parcAdminBtnImgModify:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.parcAdminAddNewImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.parcAdminAddNewImgBtn {
  margin: 10px;
  position: absolute;
  right: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 35px;
  width: 100px;
  border: none;
  background-color: var(--principale);
  color: var(--police);
  align-items: center;
}

.parcAdminAddNewImgBtn:hover {
  cursor: pointer;
  background-color: var(--bouton);
  color: var(--principale);
}

.parcAdminContainerBtnAdd {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 30px;
}

.parcAdminBtnAdd {
  border: none;
  background-color: var(--principale);
  color: var(--police);
  width: 150px;
  height: 35px;
  margin: 10px;
}

.parcAdminBtnAdd:hover {
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
}

.parcAdminList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  border: solid 2px var(--principale);
  padding: 15px;
  margin: 5px 0;
  position: relative;
}

.parcAdminContainerBtn {
  position: absolute;
  right: 0px;
}

.parcAdminBtn {
  border: none;
  background-color: var(--principale);
  color: var(--police);
  width: 100px;
  height: 35px;
  padding: 0 10px;
  margin: 0 5px;
}

.parcAdminBtn:hover {
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
}
