.containerBlogs {
  display: flex;
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerBlogs {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .containerBlogs {
    display: flex;
    flex-direction: column;
  }
}
