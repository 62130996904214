.filterCommandeAdminSection {
  .commandeAdminFiltre {
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .commandeAdminFormFiltre {
      display: flex;
      align-items: center;

      & label {
        margin: 0 15px;
      }

      .commandeAdminFiltreCommandeTraite {
        display: flex;
      }
    }
  }
}
