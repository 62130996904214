* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --principale: #587e61;
  --bouton: #f4de7e;
  --police: #ffff;
  --autre: #bfcfcb;
  --glasmoBg: #587e6174;
  --disableBtn: #565656;
  --toastify-color-success: var(--principale) !important;
  --toastify-color-warning: var(--bouton) !important;
  --toastify-icon-color-success: var(--toastify-color-success) !important;
  --toastify-icon-color-warning: var(--toastify-color-warning) !important;
  --toastify-toast-width: auto !important;
  --toastify-color-progress-success: var(--toastify-color-success) !important;
  --toastify-color-progress-warning: var(--toastify-color-warning) !important;
  --toastify-text-color-success: var(--principale) !important;
  --toastify-text-color-warning: var(--toastify-color-warning) !important;
}

body {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: justify;
  color: var(--principale);
}

.buttonGlobal {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: var(--principale);
  border: none;
  color: var(--police);
  width: auto;
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
}

.buttonGlobal:hover {
  background-color: var(--bouton);
  color: var(--principale);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.h1Body {
  font-family: "Amatic SC", cursive;
  font-weight: 800;
  color: var(--police);
  font-size: 100px;
  text-shadow: black 3px 3px 3px;
}

.h2Body {
  font-family: "Amatic SC", cursive;
  font-size: 45px;
  font-weight: 600;
  color: var(--principale);
  text-align: center;
  text-align-last: center;
  margin: 10px 0 20px 0;
}

.h3Body {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--police);
  text-transform: uppercase;
}

.h4Body {
  font-family: "Amatic SC", cursive;
  font-size: 38px;
  font-weight: 600;
  color: var(--principale);
  text-transform: uppercase;
  border: solid 2px var(--principale);
  padding: 10px;
}

.h5Body {
  font-family: "Manrope", sans-serif;
  color: var(--police);
  font-size: 20px;
}

.h1Admin {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: var(--principale);
}

.h2Admin {
  padding: 15px;
  color: var(--principale);
}

.h3Admin {
  margin: 15px 0;
  color: var(--principale);
}

.paraphAdmin {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: var(--principale);
  text-align: start;
}

.quillText {
  text-align: justify;
}

.quillText li {
  margin-left: 50px;
}

.quillText h1 {
  font-family: "Amatic SC", cursive;
  text-align: center;
  font-size: 45px;
  margin: 20px 0;
}

.quillText h2 {
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin: 20px 50px;
  font-size: 28px;
}

.quillText h3 {
  font-family: "Manrope", sans-serif;
  margin: 20px 25px;
  font-size: 18px;
}

.bgBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.popupAdminOpen {
  display: block;
  position: fixed;
  background-color: var(--police);
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 2;
  border: 1px solid var(--principale);
  padding: 15px;
  overflow-y: scroll;
}

.inputGeneralAdmin {
  width: 100%;
  height: 100%;
  border: solid 1px var(--principale);
  border-radius: 5px;
  text-align: center;
  text-align-last: center;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  z-index: 2;
  -moz-appearance: textfield;
}

.inputGeneralAdmin::-webkit-outer-spin-button,
.inputGeneralAdmin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.placeholderDisplay {
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  top: -25px;
  text-align: center;
  z-index: 99;
}

.placeholderHidden {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: 5px;
  text-align: center;
}

.labelImg {
  border: solid 1px var(--principale);
  border-radius: 5px;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;

  .newImg {
    display: none;
  }
}

.btnAddObject {
  border: solid 2px var(--principale);
  font-family: inherit;
  font-size: inherit;
  background-color: inherit;
  margin: 10px 0;
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .h1Body {
    font-size: 50px;
  }

  .h2Body {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .h1Body {
    font-size: 40px;
  }

  .h2Body {
    font-size: 30px;
  }

  .h4Body {
    font-size: 25px;
  }
}
