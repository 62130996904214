.containerAccomodationGlobal {
  // Header, carousel
  .handleAccomodationImages {
    width: 100%;
    height: 500px;

    .containerImagesMap img {
      transition: opacity 2s ease-in-out;
      height: 500px;
    }

    .active:nth-child(1) {
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0.7;
      filter: blur(10px);
      z-index: 1;
    }

    .unactive:nth-child(1) {
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0;
      filter: blur(10px);
      z-index: 1;
    }

    .active:nth-child(2) {
      position: absolute;
      object-fit: cover;
      width: 60%;
      margin: 0 20%;
      opacity: 1;
      z-index: 2;
    }

    .unactive:nth-child(2) {
      position: absolute;
      object-fit: cover;
      width: 60%;
      margin: 0 20%;
      opacity: 0;
      z-index: 2;
    }
  }

  // Titre principal
  .containerTitre {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bouton);
    width: 100%;
    height: 125px;
  }

  // Description hebergement
  .containerAccomodationDescription {
    margin-top: 75px;
    padding: 0 10%;

    .paraphAccomodation {
      height: auto;
    }

    .paraphAccomodationHidden {
      p,
      h1,
      h2,
      h3,
      li {
        display: none;
      }

      p:nth-child(1) {
        display: block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .clickTextBtn {
      font-family: "Manrope", sans-serif;
      color: var(--principale);
      border: none;
      font-size: inherit;
      background-color: inherit;
      margin: 25px 0 50px 0;
    }

    .clickTextBtn:hover {
      cursor: pointer;
    }
  }

  // Recap commande et choix activites/chambres
  .containerAccomodationGeneral {
    display: flex;
    justify-content: center;
    width: 100%;

    // Choix des activites/chambres
    .containerRoomsAndActivities {
      width: 65%;
      height: auto;
      margin-bottom: 25px;

      // Container des titres
      .lesTitres {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        margin: 20px 0;

        .unselected {
          background-color: inherit;
          border: none;
          padding: 10px 25px;

          p {
            color: var(--principale);
            font-family: "Manrope", sans-serif;
            text-transform: uppercase;
          }

          h2 {
            text-decoration: underline 2px var(--principale);
            margin: 0;
          }
        }

        .unselected:hover {
          background-color: var(--autre);
          cursor: pointer;
          transition: all 0.3s ease-out;
          transform: scale(1.1);
        }

        .selected {
          background-color: var(--autre);
          border: inherit;
          cursor: pointer;
          padding: 10px 25px;
          transition: all 0.3s ease-out;
          transform: scale(1.1);

          p {
            color: var(--principale);
            font-family: "Manrope", sans-serif;
            text-transform: uppercase;
          }

          h2 {
            margin: 0;
            text-decoration: underline 2px var(--principale);
          }
        }
      }

      // Container des listes
      .roomsAndActivities {
        display: flex;
        justify-content: center;

        .displayNone {
          display: none;
        }

        // Liste chambre
        .listeChambre {
          width: 90%;
          height: auto;
          background-color: var(--autre);

          .zoneChambre {
            padding: 25px 50px;
            .containerImg {
              display: flex;
              justify-content: center;

              .testImage2 {
                max-width: 400px;
                max-height: 250px;
                object-fit: cover;
              }
            }
          }

          .containerTitleRoom {
            display: flex;
            justify-content: center;

            .roomName {
              margin-bottom: 10px;
            }
          }

          .containerRoomPriceAndBtnAdd {
            display: flex;
            flex-direction: column;
            align-items: center;

            .containerRoomPrice {
              display: flex;
              align-items: baseline;
              margin: 15px 0;

              .roomPrice {
                font-family: "Amatic SC", cursive;
                font-size: 30px;
              }
            }
          }
        }

        // Liste activites
        .sectionActivites {
          width: 90%;
          height: auto;
          background-color: var(--autre);

          .containerActivities {
            padding: 25px 50px;
          }

          .containerImg {
            display: flex;
            justify-content: center;

            .testImage2 {
              max-width: 400px;
              max-height: 250px;
              object-fit: cover;
            }
          }

          .zoneTitre {
            display: flex;
            justify-content: center;
            text-align: center;
          }

          .titreUneActivite {
            margin: 10px 0;
          }

          .containerBtnDescriptionActivity {
            display: flex;
            justify-content: center;

            .btnDescriptionActivity {
              border: none;
              font-family: inherit;
              background-color: inherit;
              font-size: inherit;
              color: inherit;
              margin-bottom: 15px;
            }

            .btnDescriptionActivity:hover {
              cursor: pointer;
            }
          }

          .containerBtnAndPrice {
            display: flex;
            flex-direction: column;
            align-items: center;

            .paraphUneActivitePrice {
              font-family: "Amatic SC", cursive;
              font-size: 30px;
              margin: 15px 0;
            }
          }
        }
      }
    }

    // Container reservation
    .containerBooking {
      width: 35%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .reservation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        height: 80px;

        .votreReserve {
          margin: 0;
          text-decoration: underline 2px var(--principale);
          text-transform: uppercase;
        }
      }

      .tableauRecap {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--autre);
        padding: 25px 50px;
        width: 80%;
        margin-bottom: 25px;

        .resultatTableau {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px 0;
          text-align: center;
        }

        .dividerCart {
          width: 50%;
          height: 1px;
          background-color: var(--principale);
          margin: 10px 0;
        }

        .ligneDate {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          .modifDatesButton {
            height: 25px;
            margin: 10px 0;
          }

          .calendarIsOpen {
            position: absolute;
            top: 110px;
            display: block;
          }

          .calendarClosed {
            display: none;
          }

          .blockMinNight {
            display: block;
            text-align: center;
            width: 75%;
            font-weight: bold;
          }

          .noneMinNight {
            display: none;
          }
        }

        .containerCartParc {
          display: flex;
          flex-direction: column;
          align-items: center;

          .finalPrice {
            font-weight: bold;
            font-size: 25px;
          }
        }

        .containerCartAddRemovePax {
          display: flex;
          align-items: center;

          .addRemoveBtn {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: solid 1px var(--principale);
            background-color: inherit;
            margin: 0 20px;
            color: var(--principale);
          }

          .addRemoveBtn:hover {
            cursor: pointer;
          }
        }

        .validerRecap {
          margin-top: 30px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerAccomodationGlobal {
    // Header, carousel
    .handleAccomodationImages {
      height: 350px;

      .active:nth-child(1) {
        display: none;
      }

      .unactive:nth-child(1) {
        display: none;
      }

      .active:nth-child(2) {
        height: 350px;
        width: 100%;
        margin: 0;
      }

      .unactive:nth-child(2) {
        height: 350px;
        width: 100%;
        margin: 0;
      }
    }

    // Description hebergement
    .containerAccomodationDescription {
      margin-top: 50px;
      padding: 0 7%;
    }

    // Recap commande et choix activites/chambres
    .containerAccomodationGeneral {
      // Choix des activites/chambres
      .containerRoomsAndActivities {
        width: 60%;

        // Container des titres
        .lesTitres {
          height: 60px;

          .unselected:hover {
            background-color: inherit;
            cursor: inherit;
            transform: none;
            transition: none;
          }

          .selected {
            cursor: inherit;
          }
        }

        // Container des listes
        .roomsAndActivities {
          // Liste chambre
          .listeChambre {
            width: 95%;

            .zoneChambre {
              padding: 25px 30px;
            }
          }

          // Liste activites
          .sectionActivites {
            width: 95%;

            .containerActivities {
              padding: 25px 30px;
            }

            .containerImg {
              .testImage2 {
                width: 95%;
                height: 200px;
              }
            }
          }
        }
      }

      // Container reservation
      .containerBooking {
        width: 40%;

        .reservation {
          height: 60px;
        }

        .tableauRecap {
          width: 90%;
          padding: 25px 30px;
          text-align: center;

          .ligneDate {
            .calendarIsOpen {
              right: -35px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .containerAccomodationGlobal {
    // Header, carousel
    .handleAccomodationImages {
      height: 300px;

      .active:nth-child(1) {
        display: none;
      }

      .unactive:nth-child(1) {
        display: none;
      }

      .active:nth-child(2) {
        height: 300px;
        width: 100%;
        margin: 0;
      }

      .unactive:nth-child(2) {
        height: 300px;
        width: 100%;
        margin: 0;
      }
    }

    // Description hebergement
    .containerAccomodationDescription {
      margin-top: 30px;
      padding: 0 7%;
    }

    // Recap commande et choix activites/chambres
    .containerAccomodationGeneral {
      flex-direction: column;

      // Choix des activites/chambres
      .containerRoomsAndActivities {
        width: 100%;

        // Container des titres
        .lesTitres {
          height: 60px;

          .unselected:hover {
            background-color: inherit;
            cursor: inherit;
            transform: none;
            transition: none;
          }
        }

        // Container des listes
        .roomsAndActivities {
          // Liste chambre
          .listeChambre {
            width: 95%;

            .zoneChambre {
              padding: 25px 30px;
            }

            .containerRoomPrice {
              flex-wrap: wrap;
            }
          }

          // Liste activites
          .sectionActivites {
            width: 95%;

            .containerActivities {
              padding: 25px 30px;
            }

            .containerImg {
              .testImage2 {
                width: 95%;
                height: 200px;
              }
            }
          }
        }
      }

      // Container reservation
      .containerBooking {
        width: 100%;

        .reservation {
          height: 60px;
        }

        .tableauRecap {
          width: 95%;
          padding: 25px 30px;
          text-align: center;
        }
      }
    }
  }
}
