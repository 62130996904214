.lesActivitesGroupe {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;

  .UneActivite {
    display: flex;
    flex-direction: column;
    background-color: var(--autre);
    width: 45%;
    height: 440px;
    margin: 30px 0;

    .zoneImageActivite {
      text-align: center;
      width: 100%;
      height: 220px;
      min-height: 65%;
      padding: 25px 25px 0 25px;

      .testImage2 {
        width: 90%;
        height: 100%;
        object-fit: cover;
      }
    }
    .zoneText1Activite {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 60px;
      max-height: 150px;
      padding: 0 25px;

      .titreDeChaqueActivite {
        margin: 10px 0;
        text-align: center;
        width: 80%;
      }

      .infos-cart-container {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0 5px;
      }
    }

    .discover-btn {
      width: 30%;
      background-color: var(--bouton);
      color: var(--principale);
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      margin: 0 0.5em;
      border: none;
    }

    .discover-btn:hover {
      transform: scale(1.05);
      background-color: var(--bouton);
      color: var(--principale);
    }
  }
}

.containerPrice {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 50px;
  background-color: var(--principale);
  margin-top: 28px;

  .priceP:nth-child(1),
  .priceP:nth-child(3) {
    color: var(--police);
    font-weight: 400;
  }

  .priceP:nth-child(2) {
    font-family: "Amatic SC", cursive;
    font-size: 35px;
    padding: 0 8px;
    font-weight: bold;
    color: var(--police);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .infos-activites {
    flex-direction: row;
  }

  .titreDeChaqueActivite {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .lesActivitesGroupe {
    .UneActivite {
      display: flex;
      flex-direction: column;
      background-color: var(--autre);
      width: 100%;
      height: 440px;
      margin: 30px;
      position: relative;

      .containerPrice {
        .priceP:nth-child(1),
        .priceP:nth-child(3) {
          font-weight: bold;
          font-size: 14px;
        }
      }
      .discover-btn {
        width: auto;
        padding: 0 5px;
        font-size: 14px;
      }
    }

    .titreDeChaqueActivite {
      font-size: 12px;
    }
  }
  .infos-activites {
    flex-direction: row;
  }
}
