.resetPasswordSection {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .resetPwordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--principale);
    width: 40%;
    height: 50%;

    .resetPwordLogo {
      height: 150px;
    }
  }
}
