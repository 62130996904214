.PriceListAccomodation {
  .containerPriceAccomodation {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .onePrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 500px;
      border: solid 1px var(--principale);
      padding: 5px;
      margin: 10px;

      .containerBtnPrice {
        & button {
          margin: 0 5px;
        }
      }
    }
  }
}
