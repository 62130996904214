.containerOptionAdmin {
  padding-top: 20px;
  padding-left: 400px;

  .containerSelect {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .containerFilter {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}
