.containerGlobalTarifFixeAdmin {
  .sectionTarifFixeAdmin {
    padding: 20px 0 0 400px;

    .tarifFixeAdminBtnAdd {
      border: solid 2px var(--principale);
      font-family: inherit;
      font-size: inherit;
      background-color: inherit;
      margin: 10px 0;
    }

    .tarifFixeAdminBtnAdd:hover {
      cursor: pointer;
    }

    .tarifFixeAdminContainerAdd {
      display: flex;
      justify-content: center;
    }

    .tarifFixeAdminContainerForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      .tarifFixeAdminInput {
        width: 300px;
        height: 40px;
        font-size: inherit;
        margin: 15px;
        text-align: center;
        font-family: inherit;
        color: inherit;
      }
    }

    .tarifFixeAdminBtnAddTarif {
      margin: 5px;
      width: 150px;
      height: 40px;
      font-size: inherit;
      border: none;
      background-color: var(--principale);
      color: var(--police);
    }

    .tarifFixeAdminBtnAddTarif:hover {
      background-color: var(--bouton);
      color: var(--principale);
      cursor: pointer;
    }

    .tarifFixeAdminListTarif {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      border: solid 2px var(--principale);
      margin: 10px 0;

      p {
        margin-left: 10px;
      }
    }
  }
}
