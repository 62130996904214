.adminInfosPage {
  position: relative;
}

.sectionAdminInfos {
  padding-top: 20px;
  padding-left: 400px;
}

.containerForm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.containerZoneTextInfos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.titreInfos {
  width: 700px;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-align: center;
}

.zoneTextInfosPratiques {
  width: 700px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: inherit;
}

.btnModifInfosPra {
  width: 250px;
  height: 40px;
  background-color: var(--principale);
  color: white;
  border: none;
  font-size: 18px;
}

.btnModifInfosPra:hover {
  width: 250px;
  height: 40px;
  background-color: var(--bouton);
  color: var(--principale);
  font-size: 18px;
  cursor: pointer;
}
