.input-groupe {
  font-family: inherit;
  color: var(--principale);
  text-align: center;
  text-align-last: center;
  background-color: white;
  font-size: 18px;
  height: 100%;
  border: solid 1px var(--principale);
  width: 20%;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 2;
}

.input-parc-groupe {
  border-radius: 10px 0 0 10px;
}

.rechercher-groupe {
  font-family: inherit;
  color: var(--principale);
  height: 100%;
  width: 20%;
  text-align: center;
  font-size: 18px;
  background-color: var(--autre);
  border: none;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.44);
  border-radius: 0 10px 10px 0;
  z-index: 2;
}

.rechercherLinkGroupe:hover {
  color: var(--principale);
  background-color: var(--bouton);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.rechercherLinkGroupe {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-color: transparent;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 18px;
  background-color: var(--principale);
  color: white;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 0 10px 10px 0;
  z-index: 2;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .input-groupe {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .input-groupe {
    font-size: 18px;
    border-radius: 0px;
    height: 60px;
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 30px;
    text-align: center;
    text-align-last: center;
  }

  .rechercher-groupe {
    font-family: inherit;
    color: var(--principale);
    background-color: var(--autre);
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    border-radius: 0;
    height: 50px;
    outline: none;
    border-color: transparent;
    margin: 0 0 25px 0;
    border: none;
    width: 180px;
    display: flex;
    justify-content: center;
  }
  .rechercherLinkGroupe {
    border-radius: 0;
    background-color: var(--autre);
    color: var(--principale);
  }
}
