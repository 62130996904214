.sectionFormulaireContact {
  background-color: var(--fond);
  height: 100%;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 50px;
}
.containerIntroContact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.traitDiviseur {
  border-bottom: 1px solid var(--principale);
  text-align: center;
  width: 60%;
}

.containerContact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  width: 100%;
}
.containerContactAnnexe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}
.identite {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}
.cadreContact {
  background-color: var(--autre);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 80%;
  height: 150px;
}
.h1CadreContact {
  // font-family: 'Manrope', sans-serif;
  // font-weight: 500;
  // font-size: 15px;
  // color: white;
}

.pBodyContact {
  font-size: 20px;
  margin-bottom: 20px;
}

.ligne {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.formulaireContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 80%;
  padding-bottom: 50px;
}

.inputContacts {
  width: 500px;
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  margin: 20px auto;
  background-color: white;
  border: 1px solid var(--autre);
  font-family: inherit;
  color: var(--principale);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.inputMessage {
  width: 74%;
  height: 300px;
  padding-left: 15px;
  font-size: 20px;
  padding-top: 15px;
  margin: 20px auto;
  background-color: white;
  border: 1px solid var(--autre);
  font-family: inherit;
  color: var(--principale);
}
.inputContacts::placeholder {
  color: var(--principale);
  font-family: "Manrop", sans-serif;
  font-weight: 500;
}

.inputMessage::placeholder {
  font-family: "Manrop", sans-serif;
  font-weight: 500;
  color: var(--principale);
}
.couloirBouton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 74%;
}
.envoyer {
  // width: 180px;
  margin-top: 20px;
}
.envoyer:hover {
  // background-color: var(--bouton);
  // color: var(--principale);
  // cursor: pointer;
  transition: all 0.3s ease-out;
  transform: scale(1.1);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerIntroContact {
    display: flex;
    flex-direction: column;
  }

  .inputContacts {
    width: 70%;
    height: 50px;
    padding-left: 15px;
    font-size: 20px;
    margin: 20px auto;
  }

  .inputMessage {
    width: 70%;
    height: 300px;
    padding-left: 15px;
    font-size: 20px;
    padding-top: 15px;
    margin: 20px auto;
  }

  .containerContactAnnexe {
    display: none;
  }

  .formulaireContact {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .pBodyContact {
    margin: 15px 30px;
    text-align: center;
  }

  .h1BodyContact {
    // font-family: "Amatic SC", cursive;
    // font-weight: 800;
    // color: white;
    // text-align: center;
    // margin-top: 10px;
    // margin-bottom: 30px;
  }
  .sectionFormulaireContact {
    // background-color: var(--autre);
    height: 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .identite {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .ligne {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .containerIntroContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .titreContactForm {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: var(--bouton);
    padding-top: 40px;
    margin-bottom: 30px;
    padding-left: 0px;
  }
  .traitDiviseur {
    border-bottom: 1px solid white;
    text-align: center;
    width: 60%;
  }

  .paraphContactForm {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    padding-left: 0px;
    color: white;
  }

  .inputContacts {
    width: 80%;
    height: 50px;
    padding-left: 15px;
    font-size: 15px;
    color: var(--principale);
  }

  .inputMessage {
    width: 80%;
    height: 300px;
    padding-left: 15px;
    font-size: 15px;
    padding-top: 15px;
    color: var(--principale);
  }

  .containerContactAnnexe {
    display: none;
  }
  .containerContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    width: 100%;
  }
  .couloirBouton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
