.lesHebergementsGroupe {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;

  .UnHebergementGroupe {
    display: flex;
    flex-direction: column;
    background-color: var(--autre);
    width: 45%;
    height: 440px;
    margin: 30px 0;

    .zoneImage1Hebergement {
      text-align: center;
      width: 100%;
      height: 220px;
      min-height: 65%;
      padding: 25px 25px 0 25px;

      .testImage {
        width: 90%;
        height: 100%;
        object-fit: cover;
      }
    }

    .zoneText1Hebergement {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 90px;
      max-height: 150px;
      padding: 0 25px;

      .titreDeChaqueHebergement {
        margin: 10px 0;
        text-align: center;
        width: 80%;
      }
    }

    .containerPrice {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 60px;
      background-color: var(--principale);
      margin-top: 28px;

      .priceP:nth-child(1),
      .priceP:nth-child(3) {
        color: var(--police);
        font-weight: 400;
      }

      .priceP:nth-child(2) {
        font-family: "Amatic SC", cursive;
        font-size: 35px;
        padding: 0 8px;
        font-weight: bold;
        color: var(--police);
      }
    }
    .discover-btn {
      width: 30%;
      background-color: var(--bouton);
      color: var(--principale);
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      margin: 0 0.5em;
      border: none;
    }

    .discover-btn:hover {
      transform: scale(1.1);
      background-color: var(--bouton);
      color: var(--principale);
    }
  }
}

.infos-cart-container {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 5px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .infos-cart-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    p {
      margin: 2px;
    }
  }
  .titreDeChaqueHebergement {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .lesHebergementsGroupe {
    .UnHebergementGroupe {
      display: flex;
      flex-direction: column;
      background-color: var(--autre);
      width: 80%;
      height: 380px;
      margin: 30px;
      position: relative;

      .containerPrice {
        .priceP:nth-child(1),
        .priceP:nth-child(3) {
          font-weight: bold;
          font-size: 14px;
        }
      }
      .discover-btn {
        width: auto;
        padding: 0 5px;
        font-size: 14px;
      }
    }

    .titreDeChaqueHebergement {
      font-size: 12px;
    }
  }
  .infos-cart-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    p {
      margin: 2px;
    }
  }
}
