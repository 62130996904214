.modaleGlobalOpened {
  position: fixed;
  display: block;
  width: 95%;
  height: 95%;
  top: 2.5%;
  left: 2.5%;
  background-color: var(--police);
  overflow-y: scroll;
  z-index: 1000;
  border: 3px solid var(--bouton);
  border-radius: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modaleGlobalOpened::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modaleGlobalOpened {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modaleGlobalClosed {
  display: none;
}

.containerCarousel {
  height: 350px;
}

.containerMapAccomodation {
  min-height: 350px;
}

.containerImagesMapAccomodation {
  position: relative;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    position: absolute;
    transition: opacity 1s ease-in-out;
  }
}

.active-image {
  width: 100%;
  height: 350px;
  opacity: 0.7;
}

.inactive-image {
  width: 100%;
  height: 350px;
  opacity: 0;
}

.name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14%;
  background-color: var(--bouton);
}

.modale-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-bottom: 40px;
  font-family: inherit;
}

.description-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: auto;
  // overflow-y: scroll;
  width: 80vw;
  background-color: var(--autre);
  color: var(--principale);
  margin: 20px auto;
  padding: 20px;
  h3 {
    color: var(--police);
    padding: 20px;
  }
}
