.input-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  /* margin-top: 50px; */
}

.input-placeholderDisplay {
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  top: -25px;
  text-align: center;
  z-index: 99;
  color: #587e61;
  font-family: "Manrope", sans-serif;
  margin: unset;
}

.input-placeholderHidden {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: 5px;
  text-align: center;
  z-index: -1;
  color: #587e61;
  font-family: "Manrope", sans-serif;
  margin: unset;
}

.input-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  text-align-last: center;
  font-family: "Manrope", sans-serif;
  color: #587e61;
  -moz-appearance: textfield;
}

.input-input::-webkit-outer-spin-button,
.input-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
