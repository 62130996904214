.sectionConcept {
  background-color: var(--fond);
}

.containerConceptText {
  margin: 50px 400px;
  text-align: center;
}
.listeConcept {
  list-style: none;
  height: auto;
  overflow: hidden;
}

.listeConceptAll {
  list-style: none;
}

.containerBtnReadMoreAcc {
  width: 100%;
  padding-left: 0%;
}

.readMoreConcept {
  border: none;
  font-size: inherit;
  background-color: inherit;
  margin: 25px 0;
  color: var(--principale);
}

.readMoreConcept:hover {
  cursor: pointer;
}

.titreConcept {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  color: var(--principale);
  padding-top: 60px;
  padding-left: 50px;
  margin-bottom: 40px;
}
.zoneTextTrait {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.trait {
  border-bottom: 1px solid var(--principale);
  text-align: center;
  width: 60%;
}

.carteConcept {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.laCarte {
  width: 70%;
}
.containerDesParcs {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;
  margin-bottom: 40px;
  height: auto;
}
.containerDuParc {
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50vw;
  background-color: var(--autre);
}

.linkToParc {
  display: flex;
  justify-content: center;
  width: 100%;
}

.imgParcContainer {
  width: 80%;
  height: 180px;
}

.imgDoParc {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.imgDoParc:hover {
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: scale(1.05);
}
.zoneTextParc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  margin-top: 20px;
}

.paraphDescParc {
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  width: 80%;
  text-align: justify;
  height: 75px;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerConceptText {
    padding: 0 50px;
    margin: 25px;
    p {
      text-align: center;
    }
  }

  .laCarte {
    width: 100%;
  }

  .containerDesParcs {
    padding: 0 0;
  }

  .containerDuParc {
    width: 33%;
  }

  .imgDoParc {
    filter: grayscale(0%);
    height: 130px;
  }

  .imgDoParc:hover {
    transition: none;
    transform: none;
  }

  .imgParcContainer {
    width: 80%;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .containerBtnReadMoreAcc {
    padding-left: 10%;
  }

  .pBody {
    color: var(--principale);
    font-size: 15px;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 40px;
    text-align: justify;
  }

  .pBody li {
    margin-left: 10px;
  }

  .pBody h1 {
    font-family: "Amatic SC", cursive;
    font-weight: 800px;
    margin-left: 0;
  }

  .pBody h2 {
    font-family: "Manrope", cursive;
    font-weight: 600px;
    margin-left: 0;
  }

  .pBody h3 {
    font-family: "Manrope", cursive;
    font-weight: 300px;
    margin-left: 0;
  }

  .containerConceptText {
    padding: unset;
    margin: 25px 0;
    p {
      text-align: center;
    }
  }

  .titreCarte {
    font-size: 18px;
    margin-top: 50px;
    padding-left: 0px;
    text-align: center;
    margin-bottom: 20px;
  }
  .carteConcept {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .laCarte {
    width: 90%;
  }

  .containerDesParcs {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    height: auto;
  }
  .containerDuParc {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    margin-bottom: 10px;
  }

  .imgDoParc {
    max-width: 200px;
    height: 130px;
    filter: grayscale(0%);
  }

  .imgDoParc:hover {
    transition: none;
    transform: none;
  }

  .zoneTextParc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 80%;
  }

  .paraphDescParc {
    font-family: "Manrope", sans-serif;
    font-weight: 200;
    font-size: 13px;
    width: 90%;
    text-align: justify;
  }

  .imgParcContainer {
    width: 80%;
    height: auto;
  }
}

@media screen and (min-width: 1025px) {
  .containerDuParc {
    width: 30%;
  }
}

@media screen and (max-width: 380px) {
  .containerDuParc {
    max-width: 200px;
  }

  .h3Body {
    font-size: 0.8em;
  }

  .imgParcContainer {
    height: auto;
  }
}
