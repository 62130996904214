.containerListKeywords {
  margin-top: 80px;
  max-width: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.containerListKeywordsFixed {
  position: fixed;
  top: 50px;
  right: 0;
  max-width: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerListKeywordsBottom {
  animation: fadeOut ease 0.25s;
  position: fixed;
  top: 50px;
  width: 100%;
  right: 0;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

.titleBlogKeywords {
  width: 80%;
  border-bottom: 2px solid var(--principale);
  margin-top: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerListKeywords {
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }

  .containerListKeywordsFixed {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }

  .containerListKeywordsBottom {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  .containerListKeywords {
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }

  .containerListKeywordsFixed {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }

  .containerListKeywordsBottom {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    height: auto;
    top: 0;
  }
}
