.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-height: 800px;
  margin-top: 25px;
  width: 100%;

  .recap-groupe {
    background-color: var(--autre);
    width: 80%;
    margin-top: 25px;
    padding: 20px;
    height: auto;
  }

  .dividerCart {
    width: 50%;
    height: 1px;
    background-color: var(--principale);
    margin: 10px auto;
  }

  .calendar {
    margin: 0 auto;
  }

  .modifDatesButton {
    height: 25px;
    margin: 10px 0;
  }

  #groupNumber {
    width: 20%;
    margin: 0 10px;
    text-align: center;
  }

  .containerCartPax {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .group-number-label {
      width: 100%;
    }
  }

  .addRemoveBtn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px var(--principale);
    background-color: inherit;
    margin: 0 20px;
    color: var(--principale);
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Chrome */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Opéra*/
  input::-o-inner-spin-button,
  input::-o-outer-spin-button {
    -o-appearance: none;
    margin: 0;
  }
}
