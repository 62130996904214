.containerGlobalPackageInfo {
  background-color: var(--police);

  .btnClosePackageInfo {
    position: absolute;
    right: 0;
    top: 10px;
    border: solid 1px var(--principale);
    border-radius: 3px;
    background-color: inherit;
    padding: 5px 10px;
    color: inherit;
  }

  .btnClosePackageInfo:hover {
    cursor: pointer;
    border: solid 1px var(--autre);
  }

  .titlePackageInfo {
    text-align: center;
  }

  .containerFormPackageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 30px;

    .containerImgPackageInfo {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 10px;
      right: 0;

      .imgPackageInfo {
        max-width: 150px;
        max-height: 150px;
      }

      .containerNewImg {
        text-align: center;
        cursor: pointer;

        .newImg {
          display: none;
        }
      }
    }

    .containerLabelPackageInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      position: relative;
      height: 40px;
      width: 40%;

      .inputPackageInfo {
        height: 100%;
        position: absolute;
        left: 150px;
        padding: 0 5px;
        background-color: var(--police);
        border: solid 1px var(--principale);
        border-radius: 3px;
        color: inherit;
      }
    }

    .quillPackageInfo {
      height: 200px;
      padding-bottom: 60px;
      margin-top: 10px;
    }

    .containerBtnModifyPackageInfo {
      display: flex;
      justify-content: center;

      .btnModifyPackageInfo {
        border: none;
        background-color: var(--principale);
        color: var(--police);
        width: 150px;
        height: 35px;
        margin: 10px;
      }

      .btnModifyPackageInfo:hover {
        cursor: pointer;
        background-color: var(--bouton);
        color: var(--principale);
      }
    }
  }

  .containerSpan {
    display: flex;
    justify-content: center;

    .separatorHorizontal {
      border: solid 1px var(--principale);
      height: 1px;
      width: 90%;
      margin: 15px 0;
    }
  }

  .containerTwoColumns {
    width: 100%;
    display: flex;

    .containerActivity:nth-child(1) {
      border-right: solid 2px var(--principale);
    }

    .containerActivity {
      width: 50%;
      text-align: center;

      .titleTwoColumns {
        text-align: center;
      }

      .containerBtn {
        display: flex;
        justify-content: center;

        .btnAdd {
          border: solid 1px var(--principale);
          background-color: inherit;
          height: 40px;
          font-family: inherit;
          width: 325px;
          color: var(--principale);
        }

        .btnAdd:hover {
          cursor: pointer;
        }
      }

      .containerSelect {
        display: flex;
        flex-direction: column;
        align-items: center;

        .selectActivity {
          margin: 20px;
          height: 40px;
          font-family: inherit;
          color: inherit;
          background-color: inherit;
          border: solid 1px var(--principale);
          border-radius: 5px;
          text-align: center;
        }
      }

      .btnAddActivity {
        border: none;
        background-color: var(--principale);
        color: var(--police);
        width: 150px;
        height: 35px;
        margin: 10px;
      }

      .btnAddActivity:hover {
        cursor: pointer;
        background-color: var(--bouton);
        color: var(--principale);
      }

      .containerListActivities {
        display: flex;
        justify-content: center;

        .btnDeleteActivity {
          border: none;
          background-color: inherit;
          color: var(--principale);
        }

        .btnDeleteActivity:hover {
          cursor: pointer;
        }

        .listActivities {
          text-align: center;
          margin: 10px;
        }
      }

      .containerDispoPackage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .containerLabelDispoPackage {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 10px;

          .inputDispoPackage {
            height: 40px;
            width: 200px;
            text-align: center;
            font-family: inherit;
            color: inherit;
          }
        }
      }

      .containerListDispo {
        display: flex;
        justify-content: space-evenly;
        margin: 10px;

        .btnDeleteDispo {
          border: none;
          background-color: inherit;
          color: var(--principale);
        }

        .btnDeleteDispo:hover {
          cursor: pointer;
        }

        .containerStock {
          display: flex;
          align-items: center;

          .addRemoveBtn {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 1px var(--principale);
            background-color: inherit;
            margin: 0 10px;
            color: var(--principale);
          }

          .addRemoveBtn:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .separatorHorizontalOption {
    display: flex;
    flex-direction: column;
    margin: 20px auto 10px auto;
    border-top: solid 0.2px var(--principale);
    height: 0.2px;
    width: 50%;
  }

  .containerOptions {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    align-items: center;

    .containerOption2 {
      width: 80%;
      display: flex;
      margin: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .selectOption {
      height: 40px;
      font-family: inherit;
      color: inherit;
      background-color: inherit;
      border: solid 1px var(--principale);
      border-radius: 5px;
      text-align: center;
    }

    .btnAdd:hover {
      cursor: pointer;
    }

    .btnAddOptions {
      border: none;
      background-color: var(--principale);
      color: var(--police);
      width: 150px;
      height: 35px;
      margin: 10px;
    }
  }

  .containerListOptions {
    display: flex;
    justify-content: center;
  }

  .listOptions {
    text-align: center;
    margin: 5px;
  }

  .btnDeleteOptions {
    border: none;
    background-color: inherit;
    color: var(--principale);
  }

  .btnDeleteOptions:hover {
    cursor: pointer;
  }

  .containerListOptions {
    display: flex;
    justify-content: center;
  }
}
