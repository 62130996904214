.AddPriceAccomodation {
  .containerBtnAddPrice {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  & h2 {
    text-align: center;
  }

  .formAddPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    .labelHandleInfo {
      width: 400px;
      height: 40px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .inputCheckbox {
        margin-right: 5px;
      }
    }
  }
}
