.navBarAdmin {
  position: fixed;
  height: 100vh;
  width: 350px;
  background-color: var(--principale);
  display: flex;
  justify-content: center;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.navBarAdmin::-webkit-scrollbar {
  display: none;
}

.navBarAdminContainerLogo {
  display: flex;
  justify-content: center;
}

.navBarAdminLogo {
  height: 150px;
}

.navBarAdminContainerList {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
}

.navBarAdminLists {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.navBarAdminList {
  display: flex;
  justify-content: center;
  margin: 10px;
  height: 40px;
  align-items: center;
}

.navBarAdminList p:hover {
  transform: scale(1.25);
  transition: all 0.1s;
}

.displayNone {
  display: none;
}

.navBarAdminSelected {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: x-large;
  text-align: center;
  align-items: center;
}

.navBarAdminSelected p {
  padding: 8px;
  width: 100%;
  background-color: var(--police);
  color: var(--principale);
}

.navBarAdminLink {
  color: var(--police);
  text-decoration: none;
}

.navBarAdminBtnDeco {
  color: var(--bouton);
  border: none;
  background-color: inherit;
}

.navBarAdminBtnDeco:hover {
  cursor: pointer;
  transform: scale(1.25);
  transition: all 0.1s;
}

.navBarAdminBtnMaj {
  font-size: 24px;
  background: inherit;
  border: none;
  color: var(--police);
}

.navBarAdminBtnMaj:hover {
  cursor: pointer;
  transform: scale(1.25);
  transition: all 0.1s;
}
