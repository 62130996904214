.containerGlobalPackagePage {
  // Header, carousel
  .handleImages {
    width: 100%;
    height: 500px;

    .containerImagesMap img {
      transition: opacity 2s ease-in-out;
      height: 500px;
    }

    .active:nth-child(1) {
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0.7;
      filter: blur(10px);
      z-index: 1;
    }

    .unactive:nth-child(1) {
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0;
      filter: blur(10px);
      z-index: 1;
    }

    .active:nth-child(2) {
      position: absolute;
      object-fit: cover;
      width: 60%;
      margin: 0 20%;
      opacity: 1;
      z-index: 2;
    }

    .unactive:nth-child(2) {
      position: absolute;
      object-fit: cover;
      width: 60%;
      margin: 0 20%;
      opacity: 0;
      z-index: 2;
    }
  }

  // Titre principal
  .containerTitre {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bouton);
    width: 100%;
    height: 125px;
  }

  // Description package
  .containerDescriptionPackage {
    margin-top: 75px;
    padding: 0 10%;

    .paraphAccomodation {
      height: auto;
    }

    .paraphAccomodationHidden {
      p,
      h1,
      h2,
      h3,
      li {
        display: none;
      }

      p:nth-child(1) {
        display: block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .clickTextBtn {
      font-family: "Manrope", sans-serif;
      color: var(--principale);
      border: none;
      font-size: inherit;
      background-color: inherit;
      margin: 25px 0 50px 0;
    }

    .clickTextBtn:hover {
      cursor: pointer;
    }
  }

  // Descriptif package et recap commande
  .containerGeneral {
    display: flex;
    justify-content: center;
    width: 100%;

    // Descriptif package
    .containerPackageDescription {
      width: 65%;
      height: auto;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .accomodationDescription {
        width: 90%;
        border: solid 1px var(--principale);
        margin: 10px 0;

        .btnOpenSpan {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          background-color: inherit;
          font-family: inherit;
          font-size: inherit;
          color: inherit;
          border: none;
          padding: 15px;

          .arrowClose {
            padding: 0 10px;
            transform: rotate(270deg);
            transition: 0.2s linear;
          }

          .arrowOpen {
            padding: 0 10px;
            transform: rotate(90deg);
            transition: 0.2s linear;
          }
        }

        .displayBlock {
          display: block;
          padding: 0 15px;

          .titreInfos {
            color: var(--autre);
            height: auto;
            padding-top: 10px;
          }

          .description {
            padding-bottom: 15px;
          }
        }

        .displayNone {
          display: none;
        }
      }
    }

    // Recap commande
    .containerBooking {
      width: 35%;
      height: auto;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tableauRecapPackage {
        width: 80%;
        background-color: var(--autre);
        margin-top: 10px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 25px 50px;

        .resultatTableauPackage {
          margin: 10px 0;
        }

        .ligneDatePackage {
          text-align: center;

          .btnChooseDate {
            height: 25px;
            margin: 10px 0;
          }

          .displayBlock {
            display: block;

            .selectDate {
              background-color: var(--police);
              text-align: center;
              border: solid 1px var(--principale);
              border-radius: 3px;
              font-family: inherit;
            }
          }

          .displayNone {
            display: none;
          }
        }

        .addRemovePax {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0;

          .addRemoveBtn {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: solid 1px var(--principale);
            background-color: inherit;
            margin: 0 20px;
            color: var(--principale);
          }

          .addRemoveBtn:hover {
            cursor: pointer;
          }
        }

        .finalPrice {
          font-weight: bold;
          font-size: 25px;
        }

        .validerRecap {
          margin-top: 30px;
        }
      }

      .dividerCart {
        width: 50%;
        height: 1px;
        background-color: var(--principale);
        margin: 10px 0;
      }
    }
  }

  .containerOptions {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    margin: auto auto 5px auto;
    border-radius: 2px;
    text-align: center;
    padding: 1% 2%;
    justify-content: center;
    background-color: #bfcfcb;
  }

  .containerImg {
    display: flex;
    width: 300px;
    height: 150px;
    margin: auto;
    flex-direction: column;
  }

  .titreOption {
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px auto 10px auto;
    font-size: inherit;
    font-family: inherit;
    color: white;
  }

  .buttonOption {
    margin: 5px auto 5px auto;
  }

  .testImage2 {
    display: flex;
    max-width: 60%;
    // max-height: 250px;
    object-fit: cover;
    margin: auto;
  }

  .displayOption {
    background-color: #587e6174;
    // display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerGlobalPackagePage {
    // Header, carousel
    .handleImages {
      height: 350px;

      .active:nth-child(1) {
        display: none;
      }

      .unactive:nth-child(1) {
        display: none;
      }

      .active:nth-child(2) {
        height: 350px;
        width: 100%;
        margin: 0;
      }

      .unactive:nth-child(2) {
        height: 350px;
        width: 100%;
        margin: 0;
      }
    }

    // Description package
    .containerDescriptionPackage {
      margin-top: 50px;
      padding: 0 7%;
    }

    // Descriptif package et recap commande
    .containerGeneral {
      // Descriptif package
      .containerPackageDescription {
        width: 60%;

        .accomodationDescription {
          width: 95%;
        }
      }

      // Recap commande
      .containerBooking {
        width: 40%;

        .tableauRecapPackage {
          width: 90%;
        }
      }

      .testImage2 {
        display: flex;
        max-width: 60%;
        margin: auto;
      }

      .titreOption {
        font-weight: 600;
        text-transform: uppercase;
        margin: 10px auto 10px auto;
        font-size: 16px;
        font-family: inherit;
        color: white;
      }

      .containerOptions {
        max-width: 50%;
        margin: auto auto 5px auto;
        border-radius: 2px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .containerGlobalPackagePage {
    // Header, carousel
    .handleImages {
      height: 300px;

      .active:nth-child(1) {
        display: none;
      }

      .unactive:nth-child(1) {
        display: none;
      }

      .active:nth-child(2) {
        height: 300px;
        width: 100%;
        margin: 0;
      }

      .unactive:nth-child(2) {
        height: 300px;
        width: 100%;
        margin: 0;
      }
    }

    // Description package
    .containerDescriptionPackage {
      margin-top: 30px;
      padding: 0 7%;
    }

    // Descriptif package et recap commande
    .containerGeneral {
      flex-direction: column;

      // Descriptif package
      .containerPackageDescription {
        width: 100%;

        .accomodationDescription {
          width: 90%;

          .displayBlock {
            width: 100%;

            .titreInfos {
              width: 90%;
              height: auto;
              text-align: center;
            }

            .titreOption {
              font-weight: 600;
              text-transform: uppercase;
              margin: 10px auto 10px auto;
              text-align: center;
              font-size: inherit;
              font-family: inherit;
              color: white;
            }
          }
        }

        .containerOptions {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 12px;
          max-width: 50%;
          margin: auto auto 5px auto;
          border-radius: 2px;
          // padding : 25px 50px 0px 50px;
          justify-content: center;
          background-color: #bfcfcb;
        }
        .testImage2 {
          display: flex;
          max-width: 60%;
          margin: auto;
        }
      }

      // Recap commande
      .containerBooking {
        width: 100%;

        .tableauRecapPackage {
          width: 90%;
        }
      }
    }
  }
}
