.handleInfoAccomodation {
  margin-top: 50px;

  .formHandleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;

    .labelHandleInfo {
      width: 400px;
      height: 40px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .inputCheckbox {
        margin-right: 5px;
      }
    }

    .containerQuillAccomodation {
      width: 70vw;
      margin-bottom: 30px;
    }
  }
}
