.dropdown-menu {
  background-color: var(--principale);
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: var(--principale);
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: var(--bouton);
  color: var(--principale);
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 767px) {
  .fa-caret-down {
    display: none;
  }
  .dropdown-menu {
    width: 100%;
    position: relative;
    top: 0;
    list-style: none;
    text-align: center;
    height: auto;
  }
  .dropdown-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    width: 100%;
    font-size: 0.9rem;
    font-style: italic;
    padding: 16px;
  }

  .dropdown-menu li {
    background: var(--principale);
    cursor: pointer;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    all: inherit;
  }

  .dropdown-menu li:hover {
    text-decoration: none;
    background-color: jaune;
    color: #275c06 !important;
    border-radius: 0;
  }
}
