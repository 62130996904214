.handleImageAccomodation {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .containerImages {
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .oneImage {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: solid 1px var(--principale);
      padding: 10px;
      margin: 10px;

      .imgAccomodation {
        width: 250px;
        height: 250px;
        object-fit: cover;
        margin-bottom: 10px;
      }
    }
  }
}
