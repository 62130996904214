.containerGeneral {
  .title {
    text-align: center;
    text-align-last: center;
    margin-bottom: 50px;
  }

  .formModify {
    display: flex;
    flex-direction: column;
    align-items: center;

    .labelModify {
      width: 300px;
      height: 35px;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      justify-content: center;

      .subtitle {
        position: absolute;
        top: -30px;
      }
    }

    .labelModifyCheckbox {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 20px;

      .inputCheckbox {
        margin-right: 10px;
      }
    }

    .containerPriceAdmin {
      display: flex;
      justify-content: center;

      .uniquePrice {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 20px;

        .prices {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .priceName {
            margin-right: 10px;
          }

          .labelPrice {
            width: 70px;
            height: 35px;
          }
        }
      }

      .groupPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;

        .priceName {
          margin-right: 10px;
        }

        .labelPrice {
          width: 70px;
          height: 35px;
        }
      }
    }

    .containerHandleImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .allImg {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .oneImage {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 350px;
          border: solid 1px var(--principale);
          padding: 10px;
          margin: 0 20px 20px 20px;

          .imgActivity {
            width: 400px;
            height: 250px;
            object-fit: cover;
          }

          .btnDeleteImg {
            background-color: transparent;
            border: none;
            color: var(--principale);
            font-size: 20px;
            cursor: pointer;
          }
        }
      }

      .labelNewImg {
        margin-bottom: 20px;
        border: solid 1px var(--principale);
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;

        .inputNewImg {
          display: none;
        }
      }
    }
  }
}
