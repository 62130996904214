.sectionPreorder {
  .introPreorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    .traitDiviseur {
      border-bottom: 1px solid var(--principale);
      text-align: center;
      width: 60%;
    }
  }

  .formAndRecap {
    display: flex;

    .formPreorder {
      width: 65%;
      margin-top: 75px;
      display: flex;
      justify-content: center;
    }

    .cart {
      width: 35%;
      margin-top: 75px;
      display: flex;
      justify-content: center;
    }
  }

  .centrerCaptcha {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }

  .centrerCaptcha:last-child {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sectionPreorder {
    display: flex;
    flex-direction: column;
    align-items: center;

    .introPreorder {
      width: 80%;
    }

    .formAndRecap {
      width: 100%;

      .formPreorder {
        width: 55%;
      }

      .cart {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sectionPreorder {
    display: flex;
    flex-direction: column;
    align-items: center;

    .introPreorder {
      width: 90%;
    }

    .formAndRecap {
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;

      .formPreorder {
        width: 100%;
        margin-top: 0;
      }

      .cart {
        width: 90%;
        margin: 20px 0;
      }
    }
  }
}
