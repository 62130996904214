.commandeAdminInfoGlobal {
  display: block;
  position: fixed;
  background-color: var(--police);
  top: 2%;
  left: 20%;
  width: 77%;
  height: 94%;
  z-index: 2;
  border: 1px solid var(--principale);
  padding: 10px;
  overflow: scroll;
}

.commandeAdminInfoGlobalClose {
  display: none;
}

.commandeAdminInfoBtnClose {
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid 1px var(--principale);
  border-radius: 5px;
  padding: 5px 15px;
  background-color: inherit;
  font-family: inherit;
  color: inherit;
}

.commandeAdminInfoBtnClose:hover {
  cursor: pointer;
  border: solid 1px var(--autre);
}

.titleInfoCommande {
  text-align: center;
  margin-bottom: 25px;
}

.isPackage {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  animation: blinking 1s infinite linear;
}

@keyframes blinking {
  0% {
    color: var(--principale);
  }
  50% {
    color: var(--autre);
  }
}

.titleH3InfoCommande {
  text-align: center;
  margin: 20px 0;
}

.commandeAdminDate {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.commandeAdminTraiteInfo {
  display: flex;
}

.commandeAdminInfoNom {
  display: flex;
  justify-content: space-evenly;
}

.commandeAdminMail {
  text-align: center;
  margin-bottom: 15px;
}

.commandeAdminAdresseGlobale {
  text-align: center;
}

.commandeAdminAdresseVille {
  display: flex;
  justify-content: center;
}

.commandeAdminAdresseVille p {
  margin: 0 5px;
}

.commandeAdminInfoMessage {
  margin: 15px 0 25px 0;
}

.commandeAdminInfoContainerNotes {
  display: flex;
  justify-content: center;
  width: 100%;
}

.commandeAdminInfoNotes {
  width: 90%;
  height: 100px;
  font-size: inherit;
  font-family: inherit;
  padding: 10px;
  color: inherit;
}

.commandeAdminInfoContainerBtnSave {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.commandeAdminInfoSaveBtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--principale);
  background-color: initial;
  border-radius: 50px;
}

.commandeAdminInfoSaveBtn:hover {
  cursor: pointer;
  border: 1px solid var(--autre);
}

.commandeAdminInfoNumber {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.commandeAdminInfoNameAccomodation {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.commandeAdminInfoAccomodationType {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

.commandeAdminInfoActivities {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.commandeAdminInfoDateStay {
  display: flex;
  justify-content: center;
}

.commandeAdminInfoPrice {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: var(--autre);
}
